import comboHandler from "./combo.handler";
import d22Handler from "./d22.handler";

export const handlersNames = {
  COMBO: "combo",
  D22: "d22",
};

const handlersMap = {
  [handlersNames.COMBO]: comboHandler,
  [handlersNames.D22]: d22Handler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}

import React from "react";
import styled from "styled-components";
import i18n from "../../i18n";

const GoProTipStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 60px));
  background: rgba(46, 47, 53, 0.45);
  border-radius: 20px;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

const GoProTipBannerButtonStyled = styled.button`
  height: 40px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  padding-right: 8px;
  white-space: nowrap;
  background: none;
`;

const GoProTipCloseButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: none;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export class GoProTip extends React.Component {

  handleClick = () => {
    this.props.onClick && this.props.onClick();
  }

  handleCloseButtonClick = () => {
    this.props.onCloseClick && this.props.onCloseClick();
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return (
      <GoProTipStyled>
        <GoProTipBannerButtonStyled onClick={this.handleClick}>
          {i18n.t("go_pro_tip_text")}
        </GoProTipBannerButtonStyled>
        <GoProTipCloseButtonStyled onClick={this.handleCloseButtonClick}>
          <SvgCloseButtonIcon />
        </GoProTipCloseButtonStyled>
      </GoProTipStyled>
    )
  }
}

export const SvgCloseButtonIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="m0 0 24 24M24 0 0 24"/>
    </g>
  </svg>;
}
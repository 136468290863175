import Creative from "./Creative";

export default class Processing {

  static EXTRA_CREATED_AT = "created_at";
  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_PREVIEWS = "previews";
  static EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED = "pro_watermark_should_be_removed";
  static EXTRA_ITEMS = "items";
  static EXTRA_ITEMS_ORDER = "items_order";
  static EXTRA_ITEMS_ORDER_MALE = "items_order_male";
  static EXTRA_ITEMS_ORDER_FEMALE = "items_order_female";
  static EXTRA_CROPPED_IMAGE_URL = "cropped_image_url";
  static EXTRA_CROPPED_IMAGES_URLS = "cropped_images_urls";
  static EXTRA_RANDOM_GENDER = "random_gender";
  static EXTRA_IS_MULTIFACE = "is_multiface";
  static SELECTED_GROUP = "selected_group";
  static EXTRA_SELECTED_HAIR_COLOR = "selected_hair_color";
  static EXTRA_SELECTED_HAIR_STYLE = "selected_hair_style";

  _id = 0;
  _files = [];
  _groups = [];
  _creatives = [];
  _language = "en";
  _extra = {};
  _tasks = {};

  get id() {
    return this._id;
  }

  get files() {
    return this._files.map((item) => item.file);
  }

  get language() {
    return this._language;
  }

  /** @returns {Creative[]} */
  get creatives() {
    return this._creatives;
  }

  get groups() {
    return this._groups;
  }

  get extra() {
    return this._extra;
  }

  setId(id) {
    this._id = id;
  }

  setFile(file, tags) {
    this._files.push({
      file,
      tags: tags.map((tag) => String(tag)),
    });
  }

  getFile(tag) {
    const file = this._files.find((item) => item.tags.includes(String(tag)));
    return file ? file.file : null;
  }

  setGroups(groups) {
    this._groups = groups;
  }

  setLanguage(language) {
    this._language = language || "en";
  }

  setTask(key, task) {
    this._tasks[key] = task;
  }

  getTask(key) {
    return this._tasks[key] || null;
  }

  addCreative(creative) {
    this.creatives.push(creative);
  }

  replaceCreative(oldCreative, newCreative) {
    const index = this.creatives.findIndex(c => c.id === oldCreative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1, newCreative);
    }
  }

  removeCreative(creative) {
    const index = this.creatives.findIndex(c => c.id === creative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1);
    }
  }

  setExtra(key, value) {
    this._extra[key] = value;
  }

  hasExtra(key) {
    return this._extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this.hasExtra(key)
      ? this._extra[key]
      : defaultValue;
  }

  removeExtra(key) {
    delete this._extra[key];
  }

  getInProcessingCreatives() {
    return this.getStartedCreatives().filter((creative) => !creative.isFinished);
  }

  getStartedCreatives() {
    return this.creatives.filter((creative) => creative.hasExtra(Creative.EXTRA_STARTED_AT));
  }

  getStartedOrderedCreatives() {
    return this.getStartedCreatives()
      .sort((a, b) => a.getExtra(Creative.EXTRA_STARTED_AT) - b.getExtra(Creative.EXTRA_STARTED_AT))
  }

  getNonStartedCreatives() {
    return this.creatives.filter((creative) => !creative.hasExtra(Creative.EXTRA_STARTED_AT));
  }

  getNextCreatives(limit) {
    const nextCreatives = [];
    const nonStartedCreatives = this.getNonStartedCreatives();

    while (true) {
      nextCreatives.push(nonStartedCreatives.shift());

      if (nextCreatives.length >= limit || nonStartedCreatives.isEmpty()) {
        break;
      }
    }

    return nextCreatives;
  }

  getCreativesByPosition(position) {
    return this.creatives.filter((c) => c.getExtra(Creative.EXTRA_POSITION) === position);
  }

  getSelectedCreativeByPosition(position) {
    return this.getCreativesByPosition(position).find((c) => c.isSelected);
  }

  getCreativesInGroup(group) {
    return this.creatives.filter((c) => c.group === group);
  }

  getSelectedCreativeInGroup(group) {
    return this.getCreativesInGroup(group).find((c) => c.isSelected);
  }

  resolveGender(gender) {
    return gender === "human" ? this.getExtra(Processing.EXTRA_RANDOM_GENDER) : gender;
  }

  getItems() {
    return this.getExtra(Processing.EXTRA_ITEMS);
  }

  getItemsOrder(gender) {
    if (this.getExtra(Processing.EXTRA_IS_MULTIFACE)) {
      return this.getExtra(Processing.EXTRA_ITEMS_ORDER);
    }

    gender = this.resolveGender(gender || this.getExtra("gender"));
    console.log(this.getExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE));
    return gender === "male"
      ? this.getExtra(Processing.EXTRA_ITEMS_ORDER_MALE)
      : this.getExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE);
  }

  getItemById(itemId) {
    return this.getItems().find((item) => item.id === itemId);
  }

  getItemByPosition(position, gender) {
    return this.getItemById(this.getItemsOrder(gender)[position]);
  }

  toJSON(pretty = false) {
    const output = {
      id: this._id,
      files: this._files,
      groups: this._groups,
      creatives: this._creatives.map((creative) => creative.data),
      language: this._language,
      extra: this._extra,
      tasks: this._tasks,
    };

    return pretty
      ? JSON.stringify(output, null, 2)
      : JSON.stringify(output);
  }

  fromObject(obj) {
    this._id = obj.id || null;
    this._files = obj.files || null;
    this._groups = obj.groups || [];
    this._language = obj.language || "en";
    this._extra = obj.extra || {};
    this._tasks = obj.tasks || {};
    this._creatives = (obj.creatives || []).map((data) => {
      const creative = new Creative();
      creative.fromObject(data);

      return creative;
    });
  }
}
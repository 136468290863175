import React from "react";
import {logEvent, userEvents} from "../../utils/log";
import PropTypes from "prop-types";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import {assetUrl} from "../../utils/etc";
import processingManager from "../../photolab/ProcessingManager";
import Creative from "../../photolab/Creative";
import LazyImage from "../../components/LazyImage";
import ProcessingLoader from "../../components/ProcessingLoader/ProcessingLoader";
import Processing from "../../photolab/Processing";
import {CreativeTabsContainerStyled, CreativeTabsPositionContainerStyled, CreativeTabsStyled, FailedBtnStyled, FailedImageStyled, FailedStyled, FailedTextStyled, ItemStyled, OverlayStyled, BtnSoundStyled} from './CreativeView.style';
import ComparatorView from "../../components/ComparatorView";
import effects from "../../config/effects";
import {resolveCreativeAnalyticsName, transformToDownloadUrl} from "../../utils/creative";
import {webviewAnalyticsEvent, webviewShowBanner} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import {extraKeys, typeKeys} from "../../photolab/etc";
import Loader from "../../components/Loader/Loader";
import VerticalRangeControl from "../../components/VerticalRangeControl/VerticalRangeControl";
import VisibilityWatcher from "../../components/VisibilityWatcher";
import {configBuilders} from "../../photolab/config";
import PromoteAppPopup from "../result/PromoteAppPopup";
import { GoProTip } from "./GoProTip";

const effectIsMovedKey = "comparator_effect_is_changed_by_user";

export default class CreativeView extends React.Component {

  state = {
    isImageLoaded: false,
    originalImageIsShown: false,
    effect: null,
    effectIsReady: false,
    effectIsChanged: clientStorage.getBoolean(effectIsMovedKey, false),
    imagesOffset: 0,
    soundIsEnabled: false,
    videoIsPaused: false,
  };

  videoRef = null;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onDocumentVisibilityChange);
    if (!this.state.isImageLoaded) {
      this.props.handleContentLoaded(false);
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isSelected !== prevProps.isSelected) {
      this.logCreativeView();

      if (!this.state.isImageLoaded) {
        this.props.handleContentLoaded(false);
      }

      if (!this.props.isSelected && this.state.originalImageIsShown) {
        this.setState({originalImageIsShown: false});
      }

      if (this.videoRef) {
        if (this.props.isSelected) {
          this.videoRef.play();
        } else {
          this.videoRef.pause();
        }
      }
    }

    if (this.state.effectIsChanged) {
      clientStorage.setBoolean(effectIsMovedKey, true);
    }

    if (this.videoRef) {
      this.videoRef.muted = !this.state.soundIsEnabled;
    }
  }

  onDocumentVisibilityChange = () => {
    if (document.visibilityState === "visible" || !this.props.isSelected) {
      return;
    }

    const processing = processingManager.processing;
    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    if (this.state.isImageLoaded) {
      webviewAnalyticsEvent("app_enter_background", [
        clientStorage.getSelectedPhotosAmount(),
        startedImageCreatives.length,
        "",
        "",
        "",
        "template",
      ]);
    } else if (!this.props.creative.isFailed) {
      webviewAnalyticsEvent("app_enter_background", [
        clientStorage.getSelectedPhotosAmount(),
        startedImageCreatives.length,
        "",
        "",
        "",
        "generation",
      ]);
    }
  }

  handleClick = (e) => {
    console.log(`${this.props.creative.group}/${this.props.creative.templateId}`);

    this.props.onClick && this.props.onClick();
  };

  startOriginalImageShowDelay = () => {
    clearTimeout(this.longPressTimer);
    this.longPressTimer = setTimeout(() => {
      this.setState({originalImageIsShown: true});
    }, 400);
  };

  handleTouchStart = (e) => {
    this.startOriginalImageShowDelay();
  };

  handleTouchMove = (e) => {
    if (!this.state.originalImageIsShown) {
      this.startOriginalImageShowDelay();
    }
  };

  handleTouchEnd = (e) => {
    if (e.touches && e.touches.length === 0) {
      clearTimeout(this.longPressTimer);
      this.setState({originalImageIsShown: false});
    }
  };

  handleRetryProcessingClick = () => {
    this.props.creative.markAsPending();
    this.props.creative.removeExtra(Creative.EXTRA_STARTED_AT);

    processingManager.retryCreative(this.props.creative);
  };

  handleImageLoaded = (e) => {
    if (this.props.isOverlayed) {
      return;
    }

    console.log("isImageLoaded");

    this.setState({
      isImageLoaded: true
    }, () => {
      this.logCreativeView();

      if (/\.(mp4)$/i.test(this.props.creative.result)) {
        this.props.handleContentLoaded(true);
      }
    });
  };

  logCreativeView = () => {
    if (!this.state.isImageLoaded || !this.props.isSelected) {
      return;
    }

    logEvent(userEvents.CREATIVE_VIEW, {
      group: this.props.creative.group,
      template_id: this.props.creative.templateId,
      image_url: this.props.creative.result,
      gender: this.props.creative.getExtra("gender"),
      refresh_amount: this.props.creative.getExtra("refresh_amount", 0),
      seed: this.props.creative.getExtra("seed", 0)
    });

    const processing = processingManager.processing;
    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    webviewAnalyticsEvent("template_shown", [
      clientStorage.getSelectedPhotosAmount(),
      startedImageCreatives.length,
      processing.groups.findIndex((g) => g === this.props.creative.group) + 1,
      resolveCreativeAnalyticsName(this.props.creative),
      this.props.creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
    ], {
      wt_barbify3: {
        sep: this.state.effect && this.state.effect.id,
        refresh: this.props.creative.getExtra("refresh_amount", 0),
        seed: this.props.creative.getExtra("seed", 0),
        gender: this.props.creative.getExtra("gender")
      },
    });
  }

  handleImageFailedToLoad = (e) => {
    logEvent(userEvents.CREATIVE_IMAGE_LOAD_ERROR, {
      group: this.props.creative.group,
      template_id: this.props.creative.templateId,
      image_url: this.props.creative.result,
      seed: this.props.creative.getExtra("seed", 0)
    });
  };

  handleEffectClick = (effect) => {
    this.setState({effect}, this.logCreativeView);
  };

  handleVideoSoundToggleClick = () => {
    this.setState({soundIsEnabled: !this.state.soundIsEnabled});
  };

  handleDownloadAppPopupCloseClick = () => {
    processingManager.processing.setExtra(extraKeys.promoteAppPopupIsHidden, true);
    processingManager.update();
  };

  handleGoProTipClick = () => {
    webviewShowBanner("pro_web", () => {
      /*_*/
    });
  };

  handleGoProTipCloseClick = () => {
    processingManager.processing.setExtra(extraKeys.goProTipIsHidden, true);
    processingManager.update();
  };

  render() {
    const classNames = [];
    let content = null;
    const isNewTexts = false;
    const isBarbieAiBody = this.props.creative.data.group.includes(configBuilders.barbieAiBody);

    if (this.props.creative.isProcessed) {
      classNames.push("processed");
      this.state.originalImageIsShown && classNames.push("original-shown");

      const isVideo = /\.(mp4)$/i.test(this.props.creative.result);
      const withSound = this.props.creative.getExtra(extraKeys.withSound, false);

      if (isVideo) {
        content = <VisibilityWatcher
          onPause={() => this.setState({soundIsEnabled: false})}
        >
          <Loader inline backgroundColor="transparent" hidden={this.state.isImageLoaded && !this.state.videoIsPaused} />
          <video
            ref={(ref) => this.videoRef = ref}
            autoPlay
            loop
            controls={false}
            muted
            playsInline
            onWaiting={() => {
              console.log("onWaiting");
              this.setState({videoIsPaused: true});
            }}
            onPlaying={() => {
              console.log("onPlaying");
              this.setState({videoIsPaused: false});
            }}
            onLoadedData={this.handleImageLoaded}
            preload="auto">
            <source src={this.props.creative.result} type="video/mp4" />
          </video>
          <BtnSoundStyled hidden={!withSound} onClick={this.handleVideoSoundToggleClick}>
            <SvgButtonSoundOnIcon hidden={!this.state.soundIsEnabled} />
            <SvgButtonSoundOffIcon hidden={this.state.soundIsEnabled} />
          </BtnSoundStyled>
          <OverlayStyled hidden={this.props.creative.isFinished} />
        </VisibilityWatcher>;
      } else {
        if (this.state.effect == null) {
          const promoteAppPopupIsHidden = window.clientConfig.isWebview
            || !isBarbieAiBody
            || processingManager.processing.getExtra(extraKeys.promoteAppPopupIsHidden);

          const goProTipisHidden = window.clientConfig.isWeb
            || window.clientConfig.isPro
            || !isBarbieAiBody
            || processingManager.processing.getExtra(extraKeys.goProTipIsHidden);

          content = <React.Fragment>
            <LazyImage
              className="original"
              src={this.props.originalImageUrl}
              alt=""
            />
            <LazyImage
              className="result"
              src={this.props.creative.result}
              alt=""
              onLoaded={this.handleImageLoaded}
              onFailedToLoad={this.handleImageFailedToLoad}
            />
            <OverlayStyled />
            <GoProTip
              hidden={goProTipisHidden}
              onClick={this.handleGoProTipClick}
              onCloseClick={this.handleGoProTipCloseClick}
            />
            <PromoteAppPopup
              hidden={promoteAppPopupIsHidden}
              onCloseClick={this.handleDownloadAppPopupCloseClick}
            />
          </React.Fragment>;
        } else {
          content = <React.Fragment>
            <ComparatorView
              sourceImageUrl={transformToDownloadUrl(this.props.originalImageUrl)}
              resultImageUrl={transformToDownloadUrl(this.props.creative.result)}
              imagesOffset={this.state.imagesOffset / 100}
              effect={this.state.effect}
              effectAnimateOnLoad={!this.state.effectIsChanged}
              effectAnimationDuration={1000}
              effectAnimationStrength={0.15}
              effectAnimationRepeat={2}
              effectWithArrows={!this.state.effectIsChanged}
              onEffectOffsetChanged={() => this.setState({effectIsChanged: true})}
              onReadyStateChanged={(isReady) => this.setState({effectIsReady: isReady})}
              onGetResultCanvasFunc={(func) => this.props.onComparatorCanvasFunc && this.props.onComparatorCanvasFunc(func)}
              onDrawn={(data) => this.props.onComparatorCanvasDrawn && this.props.onComparatorCanvasDrawn(data)}
            />

            <VerticalRangeControl
              value={0}
              min={0}
              max={20}
              onChange={(value) => this.setState({imagesOffset: value})}
            />
            <Loader hidden={this.state.effectIsReady} inline zIndex={10} />
          </React.Fragment>;
        }
      }
    } else if (this.props.creative.isFailed) {
      classNames.push("failed");
      let contentFailed;

      if (this.props.creative.data.error?.type === "photolab") {
        contentFailed = <React.Fragment>
          <FailedTextStyled>
            {this.props.creative.data.error.message}
          </FailedTextStyled>
        </React.Fragment>;
      } else {
        contentFailed = <React.Fragment>
          <FailedTextStyled dangerouslySetInnerHTML={{__html: i18n.t("error_title")}} />
          <FailedBtnStyled onClick={this.handleRetryProcessingClick}>{i18n.t("retry")}</FailedBtnStyled>
        </React.Fragment>;
      }

      content = <FailedStyled>
        <FailedImageStyled
          src={assetUrl(`assets/images/icon-broken-heart.png`)}
          alt="" />
        {contentFailed}
      </FailedStyled>;
    } else if (!this.props.creative.isDummy) {
      let imagesUrls = processingManager.processing.getExtra(Processing.EXTRA_CROPPED_IMAGES_URLS);
      if (!imagesUrls) {
        imagesUrls = [processingManager.processing.getExtra(Processing.EXTRA_CROPPED_IMAGE_URL)];
      }

      content = <ProcessingLoader
        creative={this.props.creative}
        imagesUrls={imagesUrls}
        texts={isNewTexts ? [
          "processing_text_4",
          "processing_text_5",
          "processing_text_6",
          "processing_text_7",
          "processing_text_8",
          "processing_text_9",
          "processing_text_10",
          "processing_text_11",
          "processing_text_12",
          "processing_text_13",
          "processing_text_14",
        ] : [
          "processing_text_1",
          "processing_text_2",
          "processing_text_3",
          "processing_text_4",
          "processing_text_5",
        ]}
        textsInterval={isNewTexts ? 10_000 : 3_000}
        minTextsIntervalShow={isNewTexts ? 7_000 : 2_000}
        stroke="rgba(17,17,17,.09)"
        processingTookSecondsApprox={20}
      />;
    }

    return <>
      <ItemStyled
        className={classNames.join(" ")}
        onClick={this.handleClick}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        onTouchCancel={this.handleTouchEnd}
        children={content}
      />
      {this.props.canApplyEffects && this.props.creative.isProcessed && !isBarbieAiBody && <CreativeTabsContainerStyled>
        <CreativeTabsPositionContainerStyled>
          <CreativeTabsStyled>
            <button
              key="no"
              onClick={() => this.handleEffectClick(null)}
              className={this.state.effect === null ? "active" : ""}
              style={{backgroundImage: `url(${this.props.creative.result})`}}
            />
            {effects.map((effect) => <button
              key={effect.id}
              className={`effect--${effect.id} ${(this.state.effect && this.state.effect.id === effect.id) ? "active" : ""}`}
              style={{backgroundImage: `url(${this.getEffectPreviewUrl(effect)})`}}
              onClick={() => this.handleEffectClick(effect)}
            />)}
          </CreativeTabsStyled>
        </CreativeTabsPositionContainerStyled>
      </CreativeTabsContainerStyled>}
    </>
  }

  getEffectPreviewUrl = (effect) => {
    // const creativeGender = this.props.creative.getExtra(extraKeys.gender);
    // const gs = creativeGender === genderKeys.female ? "f" : "m";
    const gs = "f";

    return assetUrl(`assets/effects/overlays/previews/${effect.id}${gs}.jpg?v3`);
  };
}

CreativeView.contextType = AppContext;
CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
  onGetResultCanvasFunc: PropTypes.func,
  onComparatorCanvasFunc: PropTypes.func,
  onComparatorCanvasDrawn: PropTypes.func,
  // position: PropTypes.number.isRequired,
};

export function SvgButtonSoundOnIcon(props) {
  if (props.hidden) {
    return <React.Fragment />;
  }

  return <svg viewBox="0 0 120 120">
    <g fill="#FFF" fillRule="nonzero" stroke="#FFF">
      <path d="M44.541 70.526h-7.557a.997.997 0 0 1-.984-1.01V50.488c0-.557.44-1.009.984-1.009h7.557c.544 0 .984.452.984 1.01v19.029a.985.985 0 0 1-.984 1.009zm-6.563-2.028h5.579V51.506h-5.579v16.992z" />
      <path d="M61.792 84a.937.937 0 0 1-.6-.211L43.94 70.314a1.033 1.033 0 0 1-.394-.807v-19.02c0-.317.14-.615.394-.807l17.25-13.474c.3-.231.704-.27 1.042-.106.337.173.553.519.553.913V82.98c0 .385-.216.74-.553.913-.141.068-.291.106-.441.106zM45.535 69.017l15.272 11.927V39.07L45.535 50.987v18.03zM73.88 67.395a.984.984 0 0 1-.985-.985V53.593a.984.984 0 1 1 1.969 0V66.41c0 .544-.441.985-.985.985zM82.97 72.786a.984.984 0 0 1-.984-.984v-23.61a.984.984 0 1 1 1.97 0v23.6c0 .553-.441.994-.985.994z" />
    </g>
  </svg>
}

export function SvgButtonSoundOffIcon(props) {
  if (props.hidden) {
    return <React.Fragment />;
  }

  return <svg viewBox="0 0 120 120">
    <g fill="#FFF" fillRule="nonzero" stroke="#FFF">
      <path d="M44.541 70.526h-7.557a.997.997 0 0 1-.984-1.01V50.488c0-.557.44-1.009.984-1.009h7.557c.544 0 .984.452.984 1.01v19.029a.985.985 0 0 1-.984 1.009zm-6.563-2.028h5.579V51.506h-5.579v16.992z" />
      <path d="M61.792 84a.937.937 0 0 1-.6-.211L43.94 70.314a1.033 1.033 0 0 1-.394-.807v-19.02c0-.317.14-.615.394-.807l17.25-13.474c.3-.231.704-.27 1.042-.106.337.173.553.519.553.913V82.98c0 .385-.216.74-.553.913-.141.068-.291.106-.441.106zM45.535 69.017l15.272 11.927V39.07L45.535 50.987v18.03zM83.018 69.055a.974.974 0 0 1-.703-.298L66.62 52.678a1.033 1.033 0 0 1 0-1.432.973.973 0 0 1 1.397 0l15.695 16.089a1.033 1.033 0 0 1 0 1.432.966.966 0 0 1-.694.288z" />
      <path d="M67.323 69.055a.974.974 0 0 1-.703-.298 1.033 1.033 0 0 1 0-1.432l15.695-16.088a.973.973 0 0 1 1.397 0 1.033 1.033 0 0 1 0 1.432L68.017 68.767a.966.966 0 0 1-.694.288z" />
    </g>
  </svg>
}
import axios from "axios";
import {debounce} from "./etc";
import {createMd5Token} from "./text";

const eventsCache = {};
let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  is_mobile_desktop_mode: window.clientConfig.isWebMobileDesktopMode,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

// --

export const userEvents = {
  PAGE_INDEX: "page_index",
  PAGE_UPLOAD: "page_upload",
  PAGE_RESULT: "page_result",

  PHOTO_SELECT: "photo_select",
  PHOTO_SELECTED_1: "photo_selected_1",
  PHOTO_SELECTED_2: "photo_selected_2",

  PROCESSING_PROCESSED: "processing_processed",
  PROCESSING_FAILED: "processing_failed",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",
  CREATIVE_IMAGE_LOAD_ERROR: "creative_image_load_error",

  CREATIVE_VIEW: "creative_view",

  DOWNLOAD: "download",

  RATEAPP_DIALOG_SHOW: "rateapp_dialog_show",

  COMPONENT_ERROR: "component_error",
  JS_GLOBAL_ERROR: "js_global_error",

  FRONTEND_WATERMARK_FAILED: "frontend_watermark_failed",

  PURCHASE_CLICK: "purchase_click",
  PURCHASE_VISIT: "purchase_visit",

  moderationVerdict: "moderation_verdict",
  FACE_DETAILS_REQUEST_ERROR: "face_details_request_error",
  PROCESSING_ERROR: "processing_error",

  SURVEY: "survey",
  INSTAGRAM_USERNAME: "instagram_username",
};

export const hits = {
  INDEX_VISIT: 9611,
  INDEX_PHOTO_SELECT: 9612,
  PROCESSING_PROCESSED: 9613,
  PROCESSING_FAILED: 9614,
  PROCESSING_FAILED_BY_PHOTOLAB: 9615,
  RESULT_VISIT: 9616,
  RESULT_PHOTO_SELECT: 9617,
  DOWNLOAD: 9618,
  jsGlobalError: 9619,
  D22_CACHE_HIT: 9663,
  D22_CACHE_MISS: 9664,
};

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", JSON.stringify({hitName, id, count, ignoreUserGroup}));
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = (hitsCache[id] || 1) * config.increment;
      hitsCache[id] = 0;

      window.axios.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function logEvent(eventId, eventParams, cb = null, ignoreUserGroup = false) {
  eventParams = eventParams || {};
  eventParams.build_version = window.appConfig.build.version;

  if (window.appConfig.isDebug) {
    console.debug("logEvent", eventId, JSON.stringify(eventParams));
  }

  if (!window.appConfig.analytics.isEnabled) {
    return;
  }

  if (!ignoreUserGroup && !window.appConfig.analytics.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    return;
  }

  _logEvent(eventId, eventParams).then(() => {
    (typeof cb === "function") && cb();
  });
}

function _logEvent(eventId, eventParams) {
  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.webviewParams;
  }

  const eventData = {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: window.appConfig.project.name,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  };

  const eventDataHash = createMd5Token(JSON.stringify(eventData));
  if (eventsCache[eventDataHash] === true) {
    return Promise.resolve();
  }

  return axios.post(window.appConfig.analytics.endpoint, eventData).then((response) => {
    eventsCache[eventDataHash] = true;
    return response.data;
  }).then((data) => {
    if (window.clientConfig.geoipCountryCode) {
      return;
    }

    if (data.client) {
      window.clientConfig.geoipCountryCode = data.client.geoip_country_code;
      return;
    }

    try {
      const locale = new Intl.Locale(window.navigator.language);
      window.clientConfig.geoipCountryCode = locale.region || null;
    } catch (e) {/* skip */}
  }).catch((err) => {
    hitEvent(hits.ANALYTICS_ERROR, 1, true, 1);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.debug("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}

export function logCreativeResult(templateId, inputData, outputData, isDownloaded, extra = {}) {
  const logData = {
    client_token: window.clientConfig.token,
    project_name: window.appConfig.project.name,
    template_id: templateId,
    input_data: inputData,
    output_data: outputData,
    is_downloaded: isDownloaded,
    extra,
  };

  if (window.appConfig.isDebug) {
    console.debug("logCreativeResult", logData);
  }

  if (!window.appConfig.resultsCollect.isEnabled) {
    return;
  }

  if (!window.appConfig.resultsCollect.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    return;
  }

  axios.post(window.appConfig.resultsCollect.endpoint, logData).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}
import React from "react";
import {AppStoreLink, PlayStoreLink} from "../../components/AppStoreLink";
import {
  DOLLME_APPSTORE_LINK,
  DOLLME_PLAY_MARKET_LINK,
  TOONME_APPSTORE_LINK,
  TOONME_PLAY_MARKET_LINK
} from "../../utils/constants";

export default function(props) {
  const appStoreLink = window.appConfig.isBarbifyMe
    ? TOONME_APPSTORE_LINK
    : DOLLME_APPSTORE_LINK;

  const playStoreLink = window.appConfig.isBarbifyMe
    ? TOONME_PLAY_MARKET_LINK
    : DOLLME_PLAY_MARKET_LINK;

  return <React.Fragment>
    {props.appStore && <AppStoreLink link={appStoreLink} {...props.appStoreProps} />}
    {props.playStore && <PlayStoreLink link={playStoreLink} {...props.playStoreProps} />}
  </React.Fragment>;
}
import React from 'react';
import AppContext from "../../contexts/AppContext";
import {mapSelectedImageItem, webviewCall} from "../../utils/webview";
import {photolabGenderTask, photolabTask} from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {normalizeError} from "../../photolab/handlers/helpers";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl, debounce} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import ImageView from "./ImageView";
import {imageStatus} from "./shared";
import Header from '../../components/Header/Header';

const minImagesThreshold = 1;
const maxImagesThreshold = 10;
const checkImageTemplateId = 7381;

export default class PhotochooserPage extends React.Component {

  state = {
    isEditMode: false,
    images: clientStorage.getLatestSelectedImages(),
  };

  fileFieldRef = React.createRef();
  imageTasks = [];
  imagesNumber = this.state.images.isNotEmpty()
    ? this.state.images.max((i) => i.number)
    : 0;

  componentDidMount() {
    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    clientStorage.setLatestSelectedImages(this.state.images.map((image) => {
      return Object.assign({}, image, {checked: false});
    }));

    this.checkPendingImages();
  }

  checkPendingImages = () => {
    debounce("PhotochooserPage_checkPendingImages", 200, () => {
      this.state.images
        .filter((image) => image.status === imageStatus.pending)
        .forEach((image, index) => this.checkImage(image, index));
    });
  };

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      num_photos: maxImagesThreshold,
      show: "gallery",
      tab: "faces",
    });
  };

  checkImage = (image) => {
    if (this.imageTasks[image.url]) {
      return;
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(image.url))
      .addMethod(new PhotolabTaskCollageMethod({template_name: checkImageTemplateId}));

    this.imageTasks[image.url] = photolabTask(taskConfig.build())
      .then(() => photolabGenderTask(image))
      .then((taskResult) => {
        image.status = imageStatus.valid;
        image.gender = taskResult.gender.value;
        this.setState({images: this.state.images.slice()});
      })
      .catch((err) => {
        image.error = normalizeError(err);
        image.status = imageStatus.invalid;
        this.setState({images: this.state.images.slice()});
      });
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();

    data.photos
      .map((image) => mapSelectedImageItem(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.url === image.url) === -1) {
          image.status = imageStatus.pending;
          image.number = ++this.imagesNumber;
          stateImages.push(image);
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });
  };

  handleBrowserFileSelected(images) {
    const stateImages = this.state.images.slice();

    Promise.all([...images].map((image) => uploadHandler(image).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            image.status = imageStatus.pending;
            image.number = ++this.imagesNumber;
            stateImages.push(image);
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        });
      })
      .catch(console.error);
  }

  handleRemoveImages = () => {
    const images = this.state.images.filter((i) => !i.checked);

    this.setState({
      isEditMode: false,
      images,
    });
  };

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.url === image.url);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    image.checked = !image.checked;

    if (Boolean(window.navigator.vibrate)) {
      window.navigator.vibrate(200);
    }

    this.setState({
      isEditMode: this.state.images.count(_ => _.checked) > 0,
      images: this.state.images.slice(),
    });
  };

  handleImageLongClick = (image) => {
    this.handleImageClick(image);
  };

  // handleError = (err) => {
  //   console.error(err);
  //
  //   const message = err.name === "NetworkError"
  //     ? i18n.t("error__network_message")
  //     : i18n.t("error__default_message");
  //
  //   this.context.pushModal(<MessageModal
  //     key="PhotochooserPage_ErrorModal"
  //     headingText={i18n.t("error__error")}
  //     messageText={message}
  //     buttonText={"OK"}
  //   />);
  //
  //   this.context.hideLoader();
  // };

  handleProcessClick = () => {
    this.handleProcess();
  };

  handleProcess = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid)
      .slice(0, maxImagesThreshold);

    if (images.length < minImagesThreshold) {
      return;
    }

    this.props.history.push(routes.UPLOAD, {files: images})
  };

  handleAddPhotosClick = () => {
    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  handleCancelEditMode = () => {
    this.state.images.forEach((image) => image.checked = false);
    this.setState({isEditMode: false});
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    const invalidImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.invalid;
    });

    const selectButtonText = this.state.images.length > 0
      ? i18n.t("button__select_more")
      : i18n.t("button__select_photos");

    const sectionSelectClass = ["photochooser-screen"];

    if (this.state.images.length > 0) {
      sectionSelectClass.push("photochooser-screen--has-photos");
    } else {
      sectionSelectClass.push("photochooser-screen--no-photos");
    }

    return <React.Fragment>
      <div className={sectionSelectClass.join(" ")}>
        <div className="container">
          <Header
            title="photochooser"
            onHandleClick={this.handleBackButtonClick}
            />
          <div className="images-grid" hidden={this.state.images.length === 0}>
            <div className="images-grid-header">
              <p
                className="btn-upload"
                dangerouslySetInnerHTML={{__html: i18n.t("photochooser_photos_count", {num: validImagesCount})}}
              />
              <p
                className="error-text"
                dangerouslySetInnerHTML={{__html: i18n.t("photochooser_photos_error")}}
                hidden={invalidImagesCount === 0}
              />

              <button
                className="btn-select-delete"
                hidden={this.state.isEditMode}>
                {i18n.t("button__tap_to_delete")}
              </button>

              <div className="btns-option-container" hidden={!this.state.isEditMode}>
                <button
                  className="btn-option"
                  onClick={this.handleCancelEditMode}>
                  {i18n.t("button__cancel")}
                </button>

                <button
                  className="btn-option"
                  onClick={this.handleRemoveImages}>
                  <img src={assetUrl("assets/images/icon-trash.png")} alt="" />
                  {i18n.t("button__delete")}
                </button>
              </div>
            </div>

            <div className="images-grid-list">
              <button
                key="button_add_more_photos"
                className="btn-more"
                onClick={this.handleAddPhotosClick}>
                <div className="btn-more-content">
                  <img src={assetUrl("assets/images/icon-camera-black.png")} alt="" />
                  {i18n.t("button__add_more")}
                </div>
              </button>

              {this.state.images.map((image) => <ImageView
                key={image.url}
                image={image}
                onClick={this.handleImageClick}
                onLongClick={this.handleImageLongClick}
                isSelectable={this.state.isEditMode}
              />)}
            </div>
          </div>
          <ul className="list-conditions" hidden={validImagesCount >= minImagesThreshold}>
            <li>
              <img src={assetUrl("assets/images/icon-double-exclamation.png")} alt="" />
              <p dangerouslySetInnerHTML={{__html: i18n.t("photochooser_rule_1")}} />
            </li>
            <li>
              <img src={assetUrl("assets/images/icon-women.png")} alt="" />
              <p dangerouslySetInnerHTML={{__html: i18n.t("photochooser_rule_2")}} />
            </li>
            <li>
              <img src={assetUrl("assets/images/icon-cross.png")} alt="" />
              <p dangerouslySetInnerHTML={{__html: i18n.t("photochooser_rule_3")}} />
            </li>
          </ul>
          <div className="btn-container">
            <button
              className="btn-proceed"
              onClick={this.handleAddPhotosClick}
              hidden={validImagesCount >= minImagesThreshold}>
              {selectButtonText}
            </button>

            <button
              className="btn-proceed"
              onClick={this.handleProcessClick}
              hidden={validImagesCount < minImagesThreshold}>
              {i18n.t("button__proceed")}
            </button>

            <input
              className="file-field-hidden"
              type="file"
              accept="image/*"
              multiple
              ref={(ref) => this.fileFieldRef = ref}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => this.handleBrowserFileSelected(e.target.files)} />
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}

PhotochooserPage.contextType = AppContext;
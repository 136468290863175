import React from "react";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";
import {ApiResponseError, apiResponseErrorCodes} from "../utils/api";
import FileChooseButton from "./FileChooseButton";
import TouchEffect from "./TouchEffect/TouchEffect";
import {copyTextWithInvisibleElement} from "../utils/text";
import {isTokenSymbol} from "../utils/config.utils";
import {signalEvent, signals} from "../utils/signals";

export default class ErrorView extends React.Component {

  state = {
    message: "",
  };

  componentDidMount() {
    this.setState({timeStart: Date.now()});

    const isProcessingTimeoutError = this.props.error &&
      (this.props.error.type === "processing_timeout" || this.props.error.name === "CreativeTimeoutError");
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";
    const isPhotolabSystemError = isPhotolabError && this.props.error.code > -1000;

    let message;

    if (isProcessingTimeoutError) {
      message = i18n.t("error_processing_timeout");
    } else if (isPhotolabSystemError) {
      message = i18n.t("error_overload");
    } else if (isPhotolabError) {
      message = this.props.error.message;
    } else if (this.props.error instanceof ApiResponseError
      && this.props.error.code === apiResponseErrorCodes.FILE_FORMAT_INVALID) {
      message = i18n.t("error__api_code_415");
    }

    this.setState({message});
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleFileChooseButtonClick = () => {
    signalEvent(signals.photoSelectClick);
  }

  render() {
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";
    const isPreProcessingError = this.props.error && this.props.error.type === "face_details_error";
    const isUnknownError = !isPhotolabError && !isPreProcessingError;
    const isPhotolabSystemError = isPhotolabError && this.props.error.code > -1000;

    return <main className="error-page">
      <div className="container">
        <div className="error-content">
          <img src={assetUrl(`assets/images/icon-error.png`)} alt="." />
          <p>{this.state.message}</p>
          {(window.appConfig.isTestInstance || isTokenSymbol(0, "x")) && <div className="dedug">
            {JSON.stringify(this.props.error)}
            <br />
            <button onClick={() => copyTextWithInvisibleElement(JSON.stringify(this.props.error))}>copy</button>
          </div>}
        </div>

        {isPhotolabError && !isPhotolabSystemError && <FileChooseButton
          onClick={this.handleFileChooseButtonClick}
          onFilesSelected={this.props.onFilesSelected}
          className="btn-upload-foto"
          children={i18n.t("try_another_photo")} />}

        {(isPreProcessingError || isPhotolabSystemError) && <button
          onClick={this.props.onRetryClick}
          className="btn-upload-foto">
          {i18n.t("button__retry")}
          <TouchEffect/>
        </button>}

        {isUnknownError && <button
          onClick={this.props.onRestartClick}
          className="btn-upload-foto">
          {i18n.t("refresh_button")}
          <TouchEffect />
        </button>}
      </div>
    </main>;
  }
}

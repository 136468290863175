module.exports = {
    "index_logo": "Dollme",
    "index_title": "Let’s DOLL you up!",
    "index_subtitle": "Picture-perfect and ready to play.<br />Explore the dolly looks of you and get your iconic selfie with AI.",

    "index_logo_barbify_me": "Barbify",
    "index_title_barbify_me": "Let’s DOLL you up!",
    "index_subtitle_barbify_me": "Picture-perfect and ready to play.<br />Explore the dolly looks of you and get your iconic selfie with AI.",

    "index_footer_privacy": "Privacy Policy",
    "index_footer_contact": "Contact Us",

    "get_the_app": "Get the app",
    "get_free_app": "Get free app",
    "upload_choose_photo_button": "Choose photo",
    "refresh_button": "Refresh",

    "result_title": "Results",
    "result_notice_1": "You look amazing! <span class=\"text-decoration\">Share to inspire others</span>",
    "result_notice_2": "Time to show off your makeover! <span class=\"text-decoration\">Tap to share</span>",
    "result_notice_3": "Stunning looks are for sharing. <span class=\"text-decoration\">Tap if you agree</span>",
    "result_notice_4": "Don’t keep your look to yourself, <span class=\"text-decoration\">share it now!</span>",

    "try_another_photo": "Try another photo",
    "retry": "Retry",

    "photochooser_title": "Upload your photos",
    "photochooser_photos_count": "Uploaded: <span>{{num}} photos</span>",
    "photochooser_photos_error": "(Some photos cannot be used. Tap to learn more)",
    "photochooser_delete_photo": "Select a photo to delete",
    "photochooser_rule_1": "<span>At least 1 photos</span>",
    "photochooser_rule_2": "All the photos are of <span>the same person</span>",
    "photochooser_rule_3": "<span>Only one person</span> in a photo",

    "button__select_photos": "select photos",
    "button__select_more": "select more",
    "button__add_more": "Add more",
    "button__proceed": "proceed",
    "button__select_to_delete": "Select a photo to delete",
    "button__tap_to_delete": "Tap a photo to delete",
    "button__cancel": "cancel",
    "button__retry": "Retry",
    "button__try_again": "try again",
    "button__delete": "delete",
    "button__ok": "OK",
    "button__refresh": "Another look",

    "download": "Save",
    "change": "Change photo",
    "continue": "Continue",
    "subscribe": "Subscribe",
    "next": "Video",
    "prev": "Image",

    "error__default_message": "Please check your Internet connection.",
    "error_title": "Sorry, couldn’t load content.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "error_overload": "We are terribly sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",
    "error_processing_timeout": "Timeout was reached, please try again",

    "watermark_screen__title": "",
    "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
    "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
    "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
    "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
    "watermark_removal_modal__notice": "<span class=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
    "watermark_removal_modal__choice": "Your choice?",
    "watermark_removal_modal__confirm": "Remove watermark",
    "watermark_removal_modal__cancel": "Spread the good!",
    "watermark_removal_modal__close": "Close",

    "processing_title": "Processing",
    "processing_text_1": "Dolling you up. Yes, we Ken. 💪",
    "processing_text_2": "Revealing your inner doll...",
    "processing_text_3": "Oh wow, that looks aDOLLable! 😍",
    "processing_text_4": "Can't wait to show you the result!",
    "processing_text_5": "Don't you pink this dolly world is too pink? 💝",
    "processing_footer_text": "Who’s in your photo?",
    "processing_female_button": "Female",
    "processing_male_button": "Male",
    "processing_human_button": "Other",
    "processing_glamour_button": "Glamour",
    "processing_realistic_button": "Realistic",
    "processing_creative": "The world around is ever changing and your online image should too!",

    "subscribe_subscription_terms": "Renews automatically. Cancel any time.&nbsp;<span>Terms</span>",

    "cancel": "cancel",

    "subscribe_banner__text_1": "It was just a warm up.",
    "subscribe_banner__text_2": "Even spicier looks come with a premium plan.",
    "subscribe_banner__text_3": "Ads will be gone as well.",
    "subscribe_banner__subtext": "Enjoy 3 days for FREE, then {{price}}/week",

    "purchase_banner__text": "<span class=\"text-bold\">Get 100+ <span class=\"text-yellow\">premium</span> artworks</span>",
    "purchase_banner__subtext": "Enjoy more variations with {{price}} one-time purchase",

    "share_modal__title": "Saved to gallery!",
    "share_modal__copy": "Copy",
    "share_modal__copied": "Copied",
    "share_modal__text_1": "You look amazing! Share it.",
    "share_modal__text_2": "<span>We love you!</span> Love us back by adding hashtag to your post",
    "share_modal__create_animation": "Create animation",
    "share_modal__creating_animation": "Creating animation",
    "share_modal__creating_animation_dots": "Creating animation...",
    "share_modal__watch_video": "Watch the video",
    "share_modal__create_collage": "Create collage",
    "share_modal__creating_collage": "Creating collage",
    "share_modal__creating_collage_dots": "Creating collage...",
    "share_modal__watch_collage": "Watch the collage",

    "share_modal__title_web": "Saved to Gallery!",
    "share_modal__text_web": "Have even more fun and joy <br /> with our DollMe app!",

    "rewarded_ad_banner__text": "<span class=\"text-bold text-yellow\">Keep looking</span>",
    "rewarded_ad_banner__subtext": "Unlock other results by watching a short sponsored video.",

    "go_pro_tip_text": "Go PRO to remove the logo",
};


import React from "react";
import Modal from "../components/Modal";
import i18n from "../i18n";
import {AppStoreLinksContainerStyled} from "../pages/index/IndexPage.style";
import AppStoreLinks from "../pages/shared/AppStoreLinks";

export default class ShareModalWeb extends Modal {
  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });

    document.addEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  onDocumentVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      return;
    }
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleCloseButtonClick = (e) => {
    this.dismiss();
  }

  renderStoreButtons = () => {
    if (window.clientConfig.isWebMobile) {
      if (window.clientConfig.isAnyIOS) {
        return <AppStoreLinks appStore />;
      } else {
        return <AppStoreLinks playStore />;
      }
    } else {
      return <React.Fragment>
        <AppStoreLinks
          appStore
          playStore
          playStoreProps={{marginTop: 1}}
        />
      </React.Fragment>;
    }
  }

  renderModal() {
    return <React.Fragment>
      <button
        className="button-close-popup"
        onClick={this.handleCloseButtonClick}>
        <svg viewBox="0 0 16 16">
          <path strokeWidth=".5" d="M.554 2.025L6.53 8 .554 13.976c-.405.405-.405 1.064 0 1.47.406.405 1.065.405 1.47 0L8 9.47l5.975 5.975c.406.406 1.065.406 1.47 0h0c.406-.405.406-1.064 0-1.47L9.47 8l5.976-5.976h0c.405-.405.405-1.064 0-1.47-.406-.405-1.065-.405-1.47 0 0 0 0 0 0 0L8 6.53 2.024.554C1.62.15.96.15.554.554.15.96.148 1.62.554 2.024c0 0 0 0 0 0z" />
        </svg>
      </button>
      <h1 dangerouslySetInnerHTML={{__html: i18n.t("share_modal__title_web")}} />
      <p className="share-modal-text-1" dangerouslySetInnerHTML={{__html: i18n.t("share_modal__text_web")}} />
      <AppStoreLinksContainerStyled direction="column" align="center" withoutMargin={1}>
        {this.renderStoreButtons()}
      </AppStoreLinksContainerStyled>
    </React.Fragment>
  }
}

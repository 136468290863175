import styled from "styled-components"
import React from "react"
import AppStoreLinks from "../shared/AppStoreLinks";

const Container = styled.div`
	background-color: rgba(255 255 255);
	color: rgb(17 17 17);
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	z-index: 1000;
	padding: 16px 8px;
	width: calc(100% - 48px);
	max-width: calc(540px - 48px);
	left: 16px;
	bottom: 16px;
	border-radius: 25px;
`

const Header = styled.div`
	text-align: center;
	font-family: Helvetica;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	@media (max-width: 440px) {
		font-size: 15px;
		line-height: 16px;
	}
`

const ButtonsContainer = styled.div`
	display: flex;
	gap: 4px;
	width: 100%;
	justify-content: center;
	@media (max-width: 390px) {
		gap: 0;
	}
`

const CloseButton = styled.button`
	position: absolute;
	svg {
		width: 10px;
		height: 10px;
	}
	top: 10px;
	right: 14px;
	background: transparent;
`

export default function PromoteAppPopup(props) {
  if (props.hidden) {
    return <React.Fragment />;
  }

  return (
    <Container>
      <Header>Get our app to remove the logo</Header>
      <ButtonsContainer>
        <AppStoreLinks 
			appStore={(window.clientConfig.isWeb && !window.clientConfig.isWebMobile) || (window.clientConfig.isWebMobile && window.clientConfig.isAnyIOS)} 
			playStore={(window.clientConfig.isWeb && !window.clientConfig.isWebMobile) || (window.clientConfig.isWebMobile && window.clientConfig.isAnyAndroid)} 
		/>
      </ButtonsContainer>
      <CloseButton onClick={() => props.onCloseClick && props.onCloseClick()}>
        <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
          <g stroke="#A6A6A6" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="round">
            <path d="m2 2 24 24M26 2 2 26" />
          </g>
        </svg>
      </CloseButton>
    </Container>
  )
}
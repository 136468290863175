import React from "react";

export default class TestInstanceIndicator extends React.Component {

  render() {
    if (!window.appConfig.isTestInstance) {
      return <React.Fragment />;
    }

    return <div style={{
      width: 6,
      height: 6,
      borderRadius: "100%",
      background: "red",
      position: "absolute",
      top: 4,
      left: 4,
      zIndex: 100000
    }}></div>;
  }
}
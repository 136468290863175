import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import routes from "../../routes";
import * as webviewUtils from "../../utils/webview";
import AppContext from "../../contexts/AppContext";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import i18n from "../../i18n";
import {hitEvent, hits, logCreativeResult, logEvent, userEvents} from "../../utils/log";
import {assetUrl, debounce} from "../../utils/etc";
import CreativeView from "../CreativeView/CreativeView";
import {
  webviewAnalyticsEvent, webviewAppIds,
  webviewConsumable,
  webviewPreloadRewardedAd, webviewShowBanner
} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import {saveAs} from "file-saver";
import {
  resolveCreativeAnalyticsName,
  resolveCreativeShareFileName, resolveCreativeShareFileUrl,
  transformToDownloadUrl
} from "../../utils/creative";
import TouchEffect from "../../components/TouchEffect/TouchEffect";
import ShareModal from "../../components/ShareModal";
import watermarkHandler from "../../utils/watermark.handler";
import Loader from "../../components/Loader/Loader";
import FileChooseButton from "../../components/FileChooseButton";
import {BtnsGroupStyled, ButtonStyled, ButtonChooseContainerStyled, ContainerStyled, ParentStyled, SliderContainerStyled} from './ResultPage.style';
import {goToProcessing} from "../../utils/url";
import {isWebviewApp, isWebviewBuild} from "../../utils/config.utils";
import Processing from "../../photolab/Processing";
import {extraKeys, typeKeys} from "../../photolab/etc";
import buildInfo from "../../utils/build-info";
import TabView from "./TabView/TabView";
import TabsContainer from './TabsContainer/TabsContainer';
import * as api from '../../utils/api';
import {createMd5Token} from "../../utils/text";
import {signalEvent, signals} from "../../utils/signals";
import VideoButtonTextView from "./VideoButtonTextView";
import ShareModalWeb from '../../components/ShareModalWeb';
import {whenSplitInRange} from "../../photolab/config/helpers";
import Button from '../../components/Button/Button';
import {
  configBuilders,
  getVideoId
} from '../../photolab/config';

const shareHashtag = (isWebviewApp(webviewAppIds.dollme) || window.clientConfig.isWeb) ? "#dollme" : (
  isWebviewApp(
    webviewAppIds.photolabFree,
    webviewAppIds.photolabProHD,
    webviewAppIds.photolabProIos,
    webviewAppIds.photolabHuawei
  ) ? "#photolab" : "#ToonMe");

const watermarkUrl = (isWebviewApp(webviewAppIds.dollme) || window.clientConfig.isWeb)
  ? assetUrl("/assets/watermarks/dollme.png")
  : assetUrl("/assets/watermarks/barbify.png");
// const watermarkUrl = (isWebviewApp(webviewAppIds.dollme) || window.clientConfig.isWeb) ? assetUrl("/assets/watermarks/dollme.png") : (
//   isWebviewApp(
//     webviewAppIds.photolabFree,
//     webviewAppIds.photolabHuawei
//   ) ? assetUrl("/assets/watermarks/photolab.png")
//     : assetUrl("/assets/watermarks/toonme.png"));

export const watermarkConfig = {
  url: watermarkUrl,
  position: "bottom-right",
  x: 3, // %
  y: 1, // %
  percentage: 20,
};

export const watermarkConfigBarbify = {
  url: assetUrl("/assets/watermarks/barbify.png"),
  position: "bottom-right",
  x: 3, // %
  y: 1, // %
  percentage: 20,
};

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    tab: null,
    tabs: [],
    updatedAt: 0,
    rewardedAdIsLoaded: false,
    isDownloadProcessing: false,
    isRedirectionProcessing: false,
    isContentLoaded: false
  };

  activeIndex = 0;
  swiperRef = React.createRef();
  shownTabs = {};

  componentDidMount() {
    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("ResultPage.handleWebviewTabSelected", 300, () => {
          hitEvent(hits.RESULT_VISIT);
          logEvent(userEvents.PAGE_RESULT);
          webviewAnalyticsEvent("result_screen_shown", [
            clientStorage.getSelectedPhotosAmount(),
          ]);
        });
      }
    }, true);

    window.webviewEventsListeners.rewardedAdClosed.setListener((flag) => {
      if (flag && processingManager.processing) {
        processingManager.processing.setExtra("rewarded_is_shown", true);
        processingManager.processing.creatives.forEach((creative) => {
          if (creative.getExtra(Creative.EXTRA_IS_PAYABLE, false)) {
            creative.setExtra(Creative.EXTRA_IS_PAID, true);
          }
        });
        processingManager.update();
      }
    });

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_RESULT);
    }

    if (window.clientConfig.isWebview) {
      if (window.clientConfig.features.isRewardedAdModel) {
        webviewUtils.webviewIsRewardedAdPreloaded(window.clientConfig.features.rewardedAdUnitId).then((isLoaded) => {
          this.setState({rewardedAdIsLoaded: isLoaded});
        });
      }

      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();

      if (restoredProcessing) {
        try {
          processingManager.start(restoredProcessing);

          this.setState({isReady: true});
        } catch (err) {
          console.error(err);
          processingManager.clear();
          this.props.history.replace(routes.INDEX);
        }
      } else {
        processingManager.clear();
        this.props.history.replace(routes.INDEX);
      }
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  getActiveCreative = (selectedGroup) => {
    const processing = processingManager.processing;

    const activeType = this.activeIndex === 0 ? typeKeys.image : typeKeys.video;

    return processing.getCreativesInGroup(selectedGroup)
      .find((c) => {
        return selectedGroup.includes("barbie_ai_body")
            ? c.isSelected
            : c.getExtra(extraKeys.type) === activeType;
      });
  }

  handleProcessingChanged = () => {
    const processing = processingManager.processing;

    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }

    if (processing) {
      const version = processing.getExtra(extraKeys.version, 0);
      if (version < window.appConfig.processings.latestVersion) {
        processingManager.clear();
        this.props.history.replace(routes.INDEX);
      }

      processing.creatives
        .filter((c) => c.getExtra(Creative.EXTRA_AUTOSAVE, false) && c.isFinished)
        .forEach((c) => {
          c.removeExtra(Creative.EXTRA_AUTOSAVE);
          processingManager.update();

          if (c.isProcessed) {
            this.handleShare("save", c);
          }
        });

      const selectedGroup = processing.getExtra(Processing.SELECTED_GROUP) || processing.groups[0];
      const activeCreative = this.getActiveCreative(selectedGroup);

      const videoCreative = processing.getCreativesInGroup(selectedGroup)
        .find((c) => c.getExtra(extraKeys.type) === typeKeys.video);

      if (videoCreative && videoCreative.getExtra(extraKeys.isPro) !== window.clientConfig.isPro) {
        const videoSteps = videoCreative.getExtra(Creative.EXTRA_COMBO_STEPS);

        videoSteps.pop();
        videoSteps.push({id: getVideoId(), setAsFile: "raw"});

        videoCreative.setTemplateId(videoSteps.map((step) => step.id).join("_"));
        videoCreative.setExtra(Creative.EXTRA_COMBO_STEPS, videoSteps);
        videoCreative.setExtra(Creative.EXTRA_KEEP_PENDING, true);
        videoCreative.setExtra(extraKeys.isPro, window.clientConfig.isPro);

        videoCreative.markAsPending();
        videoCreative.removeExtra(Creative.EXTRA_STARTED_AT);

        processingManager.retryCreative(videoCreative);
      }

      if (activeCreative.hasExtra(Creative.EXTRA_KEEP_PENDING)) {
        activeCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);
        processingManager.update();

        this.logStartCreative(activeCreative);
      }

      if (!processing.getExtra(extraKeys.isPro) && window.clientConfig.isPro) {
        const creativesAiBody = processing.getCreativesInGroup(processing.getExtra("gender") === "male" ? "m_" + configBuilders.barbieAiBody : configBuilders.barbieAiBody);

        creativesAiBody.forEach((c) => {
          if (c.result) {
            c.markAsProcessed(c.getTask("final").resultUrl);
            c.setFile("raw", c.getTask("final").resultUrl);
          }
        })

        processing.setExtra(extraKeys.isPro, window.clientConfig.isPro);
      }

      this.setState({
        isReady: true,
        tab: selectedGroup,
        tabs: [...processing.groups],
        updatedAt: Date.now(),
      });
    }

    clientStorage.setLatestSelectedImages([]);
  };

  handleTabClick = (group, index) => {
    if (!window.clientConfig.isPro && index >= window.clientConfig.features.freeCreativesAmount) {
      webviewShowBanner("pro_web", () => {
        /*_*/
      });
      return;
    }

    const processing = processingManager.processing;

    if (this.state.tab === group) {
      return;
    }

    const nextCreative = processing.getCreativesInGroup(group)
      .find((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    const processingCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image && !c.isFinished);

    if (!nextCreative.hasExtra(Creative.EXTRA_STARTED_AT) && processingCreatives.length > 0) {
      return;
    }

    this.activeIndex = 0;

    processing.setExtra(Processing.SELECTED_GROUP, group);
    processingManager.update();
  }

  handleTabLongClick = (group) => {
    if (!window.appConfig.isTesterMode) {
      return;
    }

    this.context.showToast({
      message: group,
      delay: 2000,
    });
  }

  handleCreativeClick = (creative) => {
    if (!window.appConfig.isTesterMode) {
      return;
    }

    const name = `${creative.group}/${creative.templateId}`;

    this.context.showToast({
      message: name,
      delay: 2000,
    });

    const message = name + (creative.isProcessed ? `\n${creative.result}` : "");

    navigator.clipboard.writeText(message).then();
  }

  getCreativeByIndex = (position) => {
    const items = processingManager.processing.getItems();
    if (position >= items.length) {
      return null;
    }

    let creative = processingManager.processing.getSelectedCreativeByPosition(position);

    if (!creative) {
      creative = new Creative();
      creative.setTemplateId("dummy");
      creative.setExtra(extraKeys.contextType, processingManager.processing.getExtra(extraKeys.contextType));

      if (position >= window.clientConfig.features.freeCreativesAmount) {
        creative.setExtra(Creative.EXTRA_IS_PAYABLE, true);
      }

      if (window.clientConfig.features.isSubscribeModel && window.clientConfig.isPro) {
        creative.setExtra(Creative.EXTRA_IS_PAID, true);
      }
    }

    return creative;
  };

  getPurchase = () => {
    return this.context.purchases
      .filter((p) => p.product_id === this.context.purchaseSku.sku)
      .sort((a, b) => b.available - a.available)
      .first();
  };

  canPay = (position) => {
    if (position < window.clientConfig.features.freeCreativesAmount) {
      return true;
    }

    if (window.clientConfig.features.isSubscribeModel) {
      return window.clientConfig.isPro;
    }

    if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
      return processingManager.processing.getExtra("rewarded_is_shown", false);
    }

    const purchase = this.getPurchase();

    return purchase && purchase.available > 0;
  }

  handleActiveIndexChange = (swiper) => {
    const position = swiper.activeIndex;

    this.activeIndex = position;
    processingManager.update();
  }

  startPurchaseOrAd = () => {
    if (window.clientConfig.features.isSubscribeModel) {
      this.handleSubscribeButtonClick();
    } else if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
      webviewUtils.webviewShowRewardedAd(
        window.clientConfig.features.rewardedAdUnitId,
        "onRewardedAdShown",
        "onRewardedAdClosed"
      );
    } else if (window.clientConfig.features.isPurchaseModel) {
      this.handlePurchaseButtonClick();
    }
  };

  handleDownloadButtonClick = async (creative) => {
    hitEvent(hits.DOWNLOAD);
    logEvent(userEvents.DOWNLOAD, {
      group: creative.group,
      template_id: creative.templateId,
      seed: creative.getExtra("seed", 0)
    });

    logCreativeResult(
      `${creative.group}_${creative.templateId}`,
      processingManager.processing.files.map((f) => ({url: f.url})),
      [{url: creative.result}],
      true,
      {
        gender: creative.getExtra(extraKeys.gender),
        combo: creative.getExtra(Creative.EXTRA_COMBO_STEPS),
        skeleton: creative.getExtra("skeleton"),
      }
    );

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    let fileName = resolveCreativeShareFileName();

    if (creative.getExtra("no_wm_on_result")) {
      fileName = "raw";
    }

    let rawUrl = creative.getFile("raw");
    let effectId = null;

    if (typeof this.getResultCanvasFunc === "function") {
      const hash = createMd5Token(JSON.stringify(this.comparatorLatestDrawnData));
      const [canvas, effect] = this.getResultCanvasFunc();
      effectId = effect.id;
      fileName += "_e" + effectId + "_" + hash;

      if (creative.getFile(fileName)) {
        this.startDownload(creative, creative.getFile(fileName), effectId);
        return;
      }

      this.setState({isDownloadProcessing: true});

      const blob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 90));
      rawUrl = await api.tempImagesUploadFile(blob, "jpeg");
    }

    if (creative.getFile(fileName) || creative.getExtra(extraKeys.type) === typeKeys.video) {
      this.setState({isDownloadProcessing: false});
      this.startDownload(creative, creative.getFile(fileName), effectId);
      return;
    }

    this.setState({isDownloadProcessing: true});

    try {
      const watermark = window.clientConfig.isPro
        ? null
        : watermarkConfig;

      const resultUrl = await watermarkHandler(rawUrl, watermark, null);
      creative.setFile(fileName, resultUrl);

      this.setState({isDownloadProcessing: false});
      this.startDownload(creative, resultUrl, effectId);
    } catch (err) {
      console.error(err);

      this.setState({isDownloadProcessing: false});
      this.startDownload(creative, null, effectId);
    }
  };

  startDownload = (creative, imageUrl, effectId) => {
    this.logShareCreative(creative, "download", effectId);

    imageUrl = imageUrl || resolveCreativeShareFileUrl(creative);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewShare({
        providers: window.clientConfig.isWebviewIOS ? encodeURIComponent("[0]") : "[0]",
        imageUrl: encodeURIComponent(imageUrl),
      });

      const providers = {
        ...this.context.installedApps,
        ...{
          facebookMessenger: false,
        },
        ...(creative.getExtra(extraKeys.type) === typeKeys.video && window.clientConfig.isWebviewIOS ? {facebook: false} : {}),
        ...(creative.getExtra(extraKeys.type) === typeKeys.video ? {} : {tiktok: false}),
        ...(isWebviewBuild(8, 31) ? {} : {snapchat: false})
      };

      if (isWebviewApp(webviewAppIds.dollme) && window.clientConfig.isWebviewIOS) {
        providers.snapchat = providers.snapchat && isWebviewBuild(0, 48);
        providers.tiktok = providers.tiktok && isWebviewBuild(0, 63);
      }

      this.context.pushModal(<ShareModal
        key="ResultPage_ShareModal"
        hashtag={shareHashtag}
        providers={providers}
        creative={creative}
        shareImage={imageUrl}
        onShare={(provider) => this.handleShare(provider, creative, imageUrl, effectId)}
        onCopyButtonClick={this.logButtonClick}
      />);
    } else {
      this.context.pushModal(<ShareModalWeb
        key="ResultPage_ShareModalWeb"
      />);

      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      saveAs(transformToDownloadUrl(imageUrl), fileName);
    }

    if (!processingManager.processing.hasExtra("signal_download_is_sent")) {
      processingManager.processing.setExtra("signal_download_is_sent", true);
      signalEvent(signals.download);
    }
  };

  handleRetryCreative = (creative) => {
    creative.markAsPending();
    creative.removeExtra(Creative.EXTRA_STARTED_AT);

    processingManager.retryCreative(creative);

    this.logButtonClick(creative, "try_again");
  };

  handleShare = (provider, creative, imageUrl, effectId) => {
    imageUrl = imageUrl || resolveCreativeShareFileUrl(creative);

    const providers = [];
    provider === "save" && providers.push(webviewUtils.shareProvidersIds.save);
    provider === "snapchat" && providers.push(webviewUtils.shareProvidersIds.snapchat);
    provider === "instagram" && providers.push(webviewUtils.shareProvidersIds.instagram);
    provider === "facebook" && providers.push(webviewUtils.shareProvidersIds.facebook);
    provider === "whatsapp" && providers.push(webviewUtils.shareProvidersIds.whatsapp);
    provider === "telegram" && providers.push(webviewUtils.shareProvidersIds.telegram);
    provider === "tiktok" && providers.push(webviewUtils.shareProvidersIds.tiktok);

    webviewUtils.webviewShare({
      providers: "[" + providers.join(",") + "]",
      imageUrl: encodeURIComponent(imageUrl),
    });

    this.logShareCreative(creative, provider, effectId);
  };

  handleSlide = (direction) => {
    const activeCreative = this.getActiveCreative(this.state.tab);

    if (direction === "next") {
      this.logButtonClick(activeCreative, "video");
      this.swiperRef.slideNext();
    } else {
      this.logButtonClick(activeCreative, "image");
      this.swiperRef.slidePrev();
    }
  }

  handleSubscribeButtonClick = () => {
    webviewShowBanner("web_hotify");

    // webviewAnalyticsEvent("continue_button_tapped", [
    //   clientStorage.getSelectedPhotosAmount(),
    //   this.activeIndex + 1,
    // ]);


    // const skuItem = this.context.subscriptionSku;
    //
    // processingManager.processing.setExtra("awaiting_billed_sku", skuItem.sku);
    // processingManager.update();
    //
    // hitEvent(hits.PURCHASE_CLICK);
    // logEvent(userEvents.PURCHASE_CLICK, {
    //   sku: skuItem.sku,
    //   type: "subscription",
    // });
    //
    // clientStorage.setAwaitingBilledSku(skuItem.sku);
    //
    // webviewInApp(skuItem.sku);
  };

  handlePurchaseButtonClick = () => {
    const skuItem = this.context.purchaseSku;

    processingManager.processing.setExtra("awaiting_billed_sku", skuItem.sku);
    processingManager.update();

    hitEvent(hits.PURCHASE_CLICK);
    logEvent(userEvents.PURCHASE_CLICK, {
      sku: skuItem.sku,
      type: "inapp",
    });
    clientStorage.setAwaitingBilledSku(skuItem.sku);

    webviewConsumable(skuItem.sku);
  };

  logStartCreative(creative) {
    const processing = processingManager.processing;

    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    processing.groups.findIndex((g) => g === creative.group);

    webviewAnalyticsEvent("generation_start", [
      clientStorage.getSelectedPhotosAmount(),
      startedImageCreatives.length,
      processing.groups.findIndex((g) => g === creative.group) + 1,
      resolveCreativeAnalyticsName(creative),
      creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
      creative.getExtra("refresh_amount", 0),
    ], {
      wt_barbify3: {
        seed: creative.getExtra("seed", 0),
        gender: creative.getExtra("gender")
      },
    });
  }

  logShareCreative(creative, provider, effectId) {
    const processing = processingManager.processing;
    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    webviewAnalyticsEvent("save_and_share", [
      clientStorage.getSelectedPhotosAmount(),
      startedImageCreatives.length,
      processing.groups.findIndex((g) => g === creative.group) + 1,
      resolveCreativeAnalyticsName(creative),
      creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
      provider,
    ], {
      wt_barbify3: {
        sep: effectId,
        refresh: creative.getExtra("refresh_amount", 0),
        seed: creative.getExtra("seed", 0),
        gender: creative.getExtra("gender")
      },
    });
  }

  logButtonClick(creative, buttonName) {
    const processing = processingManager.processing;
    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    webviewAnalyticsEvent("button_tapped", [
      clientStorage.getSelectedPhotosAmount(),
      startedImageCreatives.length,
      processing.groups.findIndex((g) => g === creative.group) + 1,
      resolveCreativeAnalyticsName(creative),
      creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
      buttonName,
    ]);
  }

  handleChoosePhotoButtonClick = () => {
    signalEvent(signals.photoSelectClick);

    const activeCreative = this.getActiveCreative(this.state.tab);
    this.logButtonClick(activeCreative, "change_photo");

    webviewAnalyticsEvent("app_enter_background", [
      clientStorage.getSelectedPhotosAmount(),
      0,
      "",
      "",
      "",
      "photo_chooser",
    ]);

    buildInfo.fetch().then();

    if (window.clientConfig.features.isRewardedAdModel) {
      webviewPreloadRewardedAd(window.clientConfig.features.rewardedAdUnitId);
    }
  };

  handleFilesSelected = (files) => {
    this.setState({
      isRedirectionProcessing: true,
    });

    hitEvent(hits.RESULT_PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {place: "result"});
    webviewAnalyticsEvent("photo_selected", [
      clientStorage.getSelectedPhotosAmount(),
    ]);

    processingManager.clear();

    goToProcessing(this.props.history, files);
  };

  logShownTabs() {
    const groups = processingManager.processing.groups.filter((group) => this.shownTabs[group]);

    const params = [
      clientStorage.getSelectedPhotosAmount(),
    ];

    let data = null;
    const separator = "|";

    let i = 1;

    groups.forEach((group) => {
      if (!params[i]) {
        params[i] = group
      } else if (params[i].length + separator.length + group.length <= 40) {
        params[i] += separator + group;
      } else if (i < 5) {
        i++;
        params[i] = group;
      } else {
        if (!data) {
          data = {wt_barbify3: {templ: group}}
        } else {
          data.wt_barbify3.templ += separator + group;
        }
      }
    });

    webviewAnalyticsEvent("template_views", params, data);
  }

  handleTabVisibilityChange = (tab, isVisible) => {
    const processing = processingManager.processing;

    if (Object.keys(this.shownTabs).length < processing.groups.length) {
      this.shownTabs[tab] = isVisible;

      if (Object.keys(this.shownTabs).length === processing.groups.length) {
        this.logShownTabs();
      }

      return;
    }

    if (isVisible && !this.shownTabs[tab]) {
      this.shownTabs[tab] = true;
      this.logShownTabs();
    }
  }

  handleRefreshCreativeClick = () => {
    const processing = processingManager.processing;

    const group = this.state.tab;
    const groupCreatives = processing.getCreativesInGroup(group);

    const index = groupCreatives.findIndex((c) => c.isSelected);
    const currentCreative = groupCreatives[index];
    const nextCreative = groupCreatives[(index+1) % groupCreatives.length];

    currentCreative.setAsSelected(false);
    nextCreative.setAsSelected(true);
    nextCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);

    processingManager.update();
  };

  render() {
    if (!this.state.isReady || !processingManager.processing) {
      return <React.Fragment />;
    }

    const processing = processingManager.processing;
    const originalImageUrl = processingManager.processing.files.first().url;
    const group = this.state.tab;
    const isAiBodyTab = group.includes("barbie_ai_body");
    const groupCreatives = processing.getCreativesInGroup(group);
    const imageCreative = groupCreatives.find((c) => {
      return isAiBodyTab
        ? c.isSelected
        : c.getExtra(extraKeys.type) === typeKeys.image;
    });

    const videoCreative = groupCreatives.find((c) => c.getExtra(extraKeys.type) === typeKeys.video);
    const activeCreative = this.activeIndex === 0 ? imageCreative : videoCreative;
    const activeCreativeIsImage = activeCreative.getExtra(extraKeys.type) === typeKeys.image;
    const activeCreativeIsVideo = activeCreative.getExtra(extraKeys.type) === typeKeys.video;

    return <ParentStyled>
      <TabsContainer>
        {this.state.tabs.length > 1 && this.state.tabs.map((tab, index) => <TabView
          key={tab}
          group={tab}
          isPro={!window.clientConfig.isPro && index >= window.clientConfig.features.freeCreativesAmount}
          isActive={this.state.tab === tab}
          onClick={() => this.handleTabClick(tab, index)}
          onLongClick={() => this.handleTabLongClick(tab)}
          onVisibilityChange={(isVisible) => {this.handleTabVisibilityChange(tab, isVisible);}}
        />)}
      </TabsContainer>

      <ContainerStyled>
        <SliderContainerStyled>
          <Swiper
            key={group}
            centeredSlides={true}
            allowTouchMove={false}
            spaceBetween={10}
            slidesPerView={window.clientConfig.isWebDesktop ? 1 : 1.12}
            onActiveIndexChange={this.handleActiveIndexChange}
            onSwiper={(swiper) => this.swiperRef = swiper}
          >
            <SwiperSlide key={imageCreative.id}>
              <Button
                hidden={!isAiBodyTab || !activeCreative.isFinished || activeCreative.isFailed}
                refreshBtn={1}
                onClick={() => this.handleRefreshCreativeClick(activeCreative)}
              >
                <SvgRefreshIcon />
                {i18n.t("button__refresh")}
                <TouchEffect />
              </Button>
              <CreativeView
                isSelected={activeCreativeIsImage}
                creative={imageCreative}
                originalImageUrl={originalImageUrl}
                canApplyEffects={activeCreativeIsImage}
                onComparatorCanvasFunc={(func) => this.getResultCanvasFunc = func}
                onComparatorCanvasDrawn={(data) => this.comparatorLatestDrawnData = data}
                onClick={() => this.handleCreativeClick(imageCreative)}
                handleContentLoaded={(bool) => this.setState({isContentLoaded: bool})}
              />
            </SwiperSlide>
            {!isAiBodyTab && <SwiperSlide key={videoCreative.id}>
              <CreativeView
                isSelected={activeCreativeIsVideo}
                creative={videoCreative}
                originalImageUrl={originalImageUrl}
                onClick={() => this.handleCreativeClick(videoCreative)}
                handleContentLoaded={(bool) => this.setState({isContentLoaded: bool})}
              />
            </SwiperSlide>}
          </Swiper>
        </SliderContainerStyled>

        <BtnsGroupStyled>
          <ButtonChooseContainerStyled>
            <FileChooseButton
              onClick={this.handleChoosePhotoButtonClick}
              onFilesSelected={this.handleFilesSelected}>
              <SvgChangePhotoIcon />
              <TouchEffect />
            </FileChooseButton>
          </ButtonChooseContainerStyled>
          <ButtonStyled
            disabled={activeCreative.isDummy || !activeCreative.isProcessed || (activeCreativeIsVideo && !this.state.isContentLoaded)}
            full
            getSelectedCreativeByPosition
            svgPos="left"
            saveBtn
            onClick={() => this.handleDownloadButtonClick(activeCreative)}>
            {<SvgButtonSaveIcon />}
            {i18n.t("download")}
            <TouchEffect />
          </ButtonStyled>

          <ButtonStyled
            hidden={activeCreativeIsImage}
            transparent
            animate
            svgPos={"left"}
            isIconStyles={true}
            onClick={() => this.handleSlide("prev")}>
            <React.Fragment>
              <SvgButtonArrowIcon />
              {i18n.t("prev")}
            </React.Fragment>
            <TouchEffect />
          </ButtonStyled>

          <ButtonStyled
            hidden={activeCreativeIsVideo || isAiBodyTab}
            transparent
            animate
            svgPos={"right"}
            className='btn-video'
            isIconStyles={false}
            onClick={() => this.handleSlide("next")}>
            <React.Fragment>
              {whenSplitInRange(1, 5)
                ? <VideoButtonTextView providers={this.context.installedApps} />
                : i18n.t("next")
              }
              <SvgMoreButtonArrowIcon />
            </React.Fragment>
            <TouchEffect />
          </ButtonStyled>

        </BtnsGroupStyled>
      </ContainerStyled>

      {(this.state.isRedirectionProcessing || this.state.isDownloadProcessing) && <Loader backgroundColor="rgb(103, 172, 250,.75)" />}
    </ParentStyled>;
  }
}

export function SvgMoreButtonArrowIcon() {
  return <svg className="arrow-more" viewBox="0 0 43 42">
    <path d="M2 21h39m0 0-9-9m9 9-9 9" stroke="rgb(255, 98, 165)" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}

export function SvgButtonArrowIcon() {
  return <svg className="arrow-more" viewBox="0 0 43 42">
    <path d="M41 21H2m0 0 9-9m-9 9 9 9" stroke="rgb(255, 98, 165)" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
}

export function SvgButtonSaveIcon() {
  return <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>F617341E-D034-4E65-8B75-9621AE5F0E68</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="result_1" transform="translate(-324.000000, -2067.000000)" stroke="#fff" strokeWidth="3">
        <g id="btn" transform="translate(276.000000, 2016.000000)">
          <g id="share" transform="translate(48.000000, 51.000000)">
            <g id="9042408_download_arrow_icon-(2)" transform="translate(7.000000, 2.000000)">
              <line x1="0" y1="37.8181818" x2="28.3636364" y2="37.8181818" id="Path"></line>
              <path d="M14.1818182,0 L14.1818182,28.3636364 M14.1818182,28.3636364 L22.4545455,20.0909091 M14.1818182,28.3636364 L5.90909091,20.0909091" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
}

export function SvgChangePhotoIcon() {
  return <svg viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>94951C81-2F81-4B2F-AD95-E860EF42FB20</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="result_1" transform="translate(-126.000000, -2058.000000)" stroke="rgb(255, 98, 165)">
        <g id="Group-3" transform="translate(84.000000, 2016.000000)">
          <g id="change-photo" transform="translate(42.000000, 42.000000)">
            <g id="9042853_refresh_double_icon" transform="translate(2.790909, 2.727273)" strokeWidth="3">
              <g id="Group-2">
                <path d="M52.1415818,16.3636364 C47.9328545,6.73142727 38.3216727,0 27.1379455,0 C12.9961909,0 1.36832727,10.7636727 0,24.5454545" id="Path-2"></path>
                <path d="M44.0321576,17.7272727 L52.8321576,17.7272727 C53.4949576,17.7272727 54.0321576,17.1900127 54.0321576,16.5272727 L54.0321576,7.72727273" id="Path"></path>
                <path d="M2.26947273,38.1818182 C6.47798182,47.814 16.0893273,54.5454545 27.2729455,54.5454545 C41.4146727,54.5454545 53.0426727,43.7817273 54.4109455,30" id="Path"></path>
                <path d="M10.9090909,37.3484848 L2.10909091,37.3484848 C1.44635091,37.3484848 0.909090909,37.8856848 0.909090909,38.5484848 L0.909090909,47.3484848" id="Path"></path>
              </g>
            </g>
            <g id="9042695_media_image_icon-(2)" transform="translate(20.000000, 20.000000)">
              <path d="M19.3333333,-1.5 C19.9316833,-1.5 20.47333,-1.25747787 20.865402,-0.865405863 C21.2574895,-0.473318342 21.5,0.0683485933 21.5,0.666666667 L21.5,0.666666667 L21.5,19.3333333 C21.5,19.9316944 21.2574796,20.4733408 20.8654102,20.8654102 C20.4733408,21.2574796 19.9316944,21.5 19.3333333,21.5 L19.3333333,21.5 L0.666666667,21.5 C0.0683485933,21.5 -0.473318342,21.2574895 -0.865405863,20.865402 C-1.25747787,20.47333 -1.5,19.9316833 -1.5,19.3333333 L-1.5,19.3333333 L-1.5,0.666666667 C-1.5,0.0683597222 -1.25748781,-0.473307537 -0.865397672,-0.865397672 C-0.473307537,-1.25748781 0.0683597222,-1.5 0.666666667,-1.5 L0.666666667,-1.5 L19.3333333,-1.5 Z" id="Path" strokeWidth="3"></path>
              <polyline id="Path" strokeWidth="3" points="0 14.4444444 7.77777778 11.1111111 20 16.6666667"></polyline>
              <path d="M14.4444444,7.77777778 C13.2171111,7.77777778 12.2222222,6.78285556 12.2222222,5.55555556 C12.2222222,4.32825556 13.2171111,3.33333333 14.4444444,3.33333333 C15.6717778,3.33333333 16.6666667,4.32825556 16.6666667,5.55555556 C16.6666667,6.78285556 15.6717778,7.77777778 14.4444444,7.77777778 Z" id="Path" strokeWidth="2"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
}

export function SvgRefreshIcon() {
  return <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="p72jw7j4da" d="M0 0h48v48H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="qijl1bu1qb" fill="#fff">
        <use href="#p72jw7j4da" />
      </mask>
      <use fillOpacity="0" fill="#D8D8D8" href="#p72jw7j4da" />
      <path d="M27.686 3C16.613 2.999 7.582 11.873 7.391 22.945l-.004.018v.055c0 .078-.011.153-.011.23 0 .021.01.039.011.059v6.734l-4.146-4.403A1.876 1.876 0 1 0 .51 28.21l7.386 7.844c.007.007.017.01.024.016l.042.041c.007.007.009.017.016.023.088.067.181.126.28.176.08.066.168.125.26.175l.013.01c.009.004.02.002.028.006.45.189.957.189 1.406 0l.028-.007.01-.007c.088-.049.171-.105.25-.168.104-.052.203-.115.294-.186.008-.007.01-.017.017-.024l.041-.042c.007-.007.016-.01.023-.016l7.362-7.844a1.875 1.875 0 1 0-2.733-2.566l-4.12 4.39v-7.012c.095-9.122 7.55-16.447 16.672-16.383 9.121.065 16.472 7.496 16.438 16.618-.034 9.122-7.44 16.497-16.562 16.494a1.875 1.875 0 1 0 0 3.75 20.25 20.25 0 1 0 0-40.498z" fill="#FFF" fillRule="nonzero" mask="url(#qijl1bu1qb)" />
    </g>
  </svg>

}

ResultPage.contextType = AppContext;

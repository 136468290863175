export const genderKeys = {
  male: "male",
  female: "female",
};

export const extraKeys = {
  version: "version",
  gender: "gender",
  type: "type",
  withSound: "with_sound",
  isPro: "is_pro",
  promoteAppPopupIsHidden: "promote_app_popup_is_hidden",
  goProTipIsHidden: "go_pro_tip_is_hidden",
  withHairLength: "with_hair_length",
  seed: "seed",
  sdxlWatermarkTemplate: "sdxl_wm_template",
  noWatermarkOnResult: "no_wm_on_result",
};

export const typeKeys = {
  video: "video",
  image: "image",
};

export const taskKeys = {
  cropV15: "crop_v15",
  questions: "questions",
};
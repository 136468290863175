function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("ResponseError");
    error.name = "ResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

export function store(optionsArg) {
  const options = Object.assign({}, optionsArg);

  ["disk", "file", "fileName"].forEach((param) => {
    if (!options[param]) {
      throw new Error(`No '${param}' parameter`);
    }
  });

  return fetch(window.appConfig.paths.imagesStore, {
    method: "POST",
    headers: {
      "Accept": "application/json",
    },
    body: JSON.stringify({
      disk: options.disk,
      file: options.file,
      file_name: options.fileName,
      file_type: options.fileType,
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

import React from "react";
import Modal from "../components/Modal";
import PropTypes from "prop-types";
import i18n from "../i18n";
import {copyTextWithInvisibleElement} from "../utils/text";
import processingManager from "../photolab/ProcessingManager";
import {extraKeys, typeKeys} from "../photolab/etc";
import {webviewAnalyticsEvent, webviewAppIds} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {isWebviewApp} from "../utils/config.utils";
import * as processingHelper from "../helpers/processing.helper";
import * as webviewUtils from "../utils/webview";

export default class ShareModal extends Modal {

  state = {
    copied: false,
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });

    document.addEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onDocumentVisibilityChange);
  }

  onDocumentVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      return;
    }

    const processing = processingManager.processing;
    const startedImageCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

    webviewAnalyticsEvent("app_enter_background", [
      clientStorage.getSelectedPhotosAmount(),
      startedImageCreatives.length,
      "",
      "",
      "",
      "save_and_share",
    ], {
      wt_barbify3: {
        seed: this.props.creative.getExtra("seed", 0),
        gender: this.props.creative.getExtra("gender")
      },
    });
  }

  dismiss(trigger) {
    if (isWebviewApp(webviewAppIds.dollme)) {
      processingHelper.runOnceByExtra(processingManager.processing, "rate_app_on_download", () => {
        webviewUtils.webviewRateApp((res) => {/**/});
      });
    }

    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleCloseButtonClick = (e) => {
    this.dismiss();
  }

  handleCopyButtonClick = () => {
    this.props.onCopyButtonClick && this.props.onCopyButtonClick(this.props.creative, "copy");

    if (copyTextWithInvisibleElement(this.props.hashtag)) {
      this.setState({copied: true});
    }
  }

  renderModal() {
    return <React.Fragment>
      <button
        className="button-close-popup"
        onClick={this.handleCloseButtonClick}>
        <svg viewBox="0 0 16 16">
          <path strokeWidth=".5" d="M.554 2.025L6.53 8 .554 13.976c-.405.405-.405 1.064 0 1.47.406.405 1.065.405 1.47 0L8 9.47l5.975 5.975c.406.406 1.065.406 1.47 0h0c.406-.405.406-1.064 0-1.47L9.47 8l5.976-5.976h0c.405-.405.405-1.064 0-1.47-.406-.405-1.065-.405-1.47 0 0 0 0 0 0 0L8 6.53 2.024.554C1.62.15.96.15.554.554.15.96.148 1.62.554 2.024c0 0 0 0 0 0z" />
        </svg>
      </button>
      <h1 dangerouslySetInnerHTML={{__html: i18n.t("share_modal__title")}} />
      <p className="share-modal-text-1" dangerouslySetInnerHTML={{__html: i18n.t("share_modal__text_1")}} />
      <p className="share-modal-text-2" dangerouslySetInnerHTML={{__html: i18n.t("share_modal__text_2")}} />

      <div className="copy-container">
        <p>{this.props.hashtag}</p>
        <button
          onClick={this.handleCopyButtonClick}>
          {!this.state.copied && <>
            <svg viewBox="0 0 15 15" fill="none">
              <g clipPath="url(#r0mu89jzpa)" stroke="#fff" strokeWidth="1.2" strokeLinejoin="round">
                <path d="M11.924 3.75H5.42a1.67 1.67 0 0 0-1.67 1.67v6.504c0 .922.748 1.67 1.67 1.67h6.504a1.67 1.67 0 0 0 1.67-1.67V5.42a1.67 1.67 0 0 0-1.67-1.67z" />
                <path d="m11.235 3.75.015-.703a1.645 1.645 0 0 0-1.64-1.64H3.28A1.88 1.88 0 0 0 1.406 3.28V9.61a1.645 1.645 0 0 0 1.64 1.641h.704" strokeLinecap="round" />
              </g>
              <defs>
                <clipPath id="r0mu89jzpa">
                  <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
              </defs>
            </svg>
            {i18n.t("share_modal__copy")}
          </>}

          {this.state.copied && <>
            <svg viewBox="0 0 15 15" fill="none">
              <path d="m3.125 8.125 2.5 2.5 6.25-6.25" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {i18n.t("share_modal__copied")}
          </>}
        </button>
      </div>

      <div className="btns-container">
        <div className="btns-wrapper">
          {Object.keys(this.props.providers).map((provider) => <button
            key={provider}
            hidden={!this.props.providers[provider]}
            className={"btn-" + provider}
            onClick={() => this.props.onShare(provider)}
          />)}
        </div>
      </div>
    </React.Fragment>
  }
}

ShareModal.propTypes = {
  onShare: PropTypes.func.isRequired,
  hashtag: PropTypes.string,
};

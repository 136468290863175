import {getHandlerByName} from "./handlers";
import Processing from "./Processing";
import * as api from "../utils/api";
import Creative from "./Creative";
import {throttle} from "../utils/etc";
import {hitEvent, hits, logCreativeResult, logEvent, userEvents} from "../utils/log";
import {creativeName, resolveCreativeAnalyticsName} from "../utils/creative";
import {extraKeys, typeKeys} from "./etc";
import {webviewAnalyticsEvent} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {photolabTask} from "./api";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";

const localStorageKey = "barbify3:processing";

export class ProcessingManager {

  _listeners = [];
  _processing = null;
  _handlers = [];

  /** @return {Processing} */
  get processing() {
    return this._processing;
  }

  /** @param {Processing} processing */
  start = (processing) => {
    this.stop();

    this._processing = processing;

    if (!processing.hasExtra(Processing.EXTRA_STARTED_AT)) {
      processing.setExtra(Processing.EXTRA_STARTED_AT, Date.now());
    }

    window.localStorage.setItem(localStorageKey, processing.toJSON());

    this.update();
  };

  retryCreative = (creative) => {
    const pos = this._handlers.findIndex((item) => item.id === creative.id);
    if (pos > -1) {
      this._handlers.splice(pos, 1);
    }

    this.update();
  };

  logCreativeResult = (processing, creative) => {
    logCreativeResult(
      `${creative.group}_${creative.templateId}`,
      processing.files.map((f) => ({url: f.url})),
      [{url: creative.result}],
      false,
      {
        gender: creative.getExtra(extraKeys.gender),
        combo: creative.getExtra(Creative.EXTRA_COMBO_STEPS),
        skeleton: creative.getExtra("skeleton"),
      }
    );
  };

  update = () => {
    if (!this.processing) {
      return;
    }

    const processing = this.processing;

    processing.creatives.forEach((creative) => {
      if (creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) === true) {
        return;
      }

      if (creative.isFinished) {
        return;
      }

      const isActive = this._handlers.findIndex((item) => item.id === creative.id) > -1;
      if (isActive) {
        return;
      }

      const handler = getHandlerByName(creative.handler);
      if (handler === null) {
        throw new Error("Unrecognized handler name: " + creative.handler);
      }

      if (!creative.hasExtra(Creative.EXTRA_STARTED_AT)) {
        creative.setExtra(Creative.EXTRA_STARTED_AT, Date.now());

        hitEvent(hits.CREATIVE_STARTED);
        logEvent(userEvents.CREATIVE_STARTED, {
          group: creative.group,
          template_id: creativeName(creative),
          seed: creative.getExtra("seed", 0)
        });
      }

      const handlerPromise = handler(processing, creative)
        .then((creative) => {
          const isPaid = creative.getExtra(Creative.EXTRA_IS_PAYABLE) && creative.getExtra(Creative.EXTRA_IS_PAID);

          this.tick();
          hitEvent(hits.CREATIVE_PROCESSED);
          if (isPaid) {
            hitEvent(hits.CREATIVE_PROCESSED_PAID);

            if (!processing.getExtra("creative_processed_paid_hit")) {
              processing.setExtra("creative_processed_paid_hit", true);
              hitEvent(hits.CREATIVE_PROCESSED_PAID_ONCE_PROCESSING);
            }
          }

          logEvent(userEvents.CREATIVE_PROCESSED, {
            group: creative.group,
            template_id: creativeName(creative),
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
            is_paid: isPaid,
            seed: creative.getExtra("seed", 0)
          });

          if (creative.group === "barbie_ai_body" || creative.group === "m_barbie_ai_body") {
            const skeletonTaskConfig = new PhotolabTaskBuilder()
              .addMethod(new PhotolabTaskCollageMethod({template_name: 8800}))
              .addImage(new PhotolabTaskImageUrl(creative.result))
              .buildToJs();

            photolabTask(skeletonTaskConfig, {
              timeout: 250,
              interval: 250
            }).then((taskResult) => {
              creative.setExtra("skeleton", taskResult);
              this.logCreativeResult(processing, creative);
            }).catch((err) => {
              console.error(err);
              this.logCreativeResult(processing, creative);
            });
          } else {
            this.logCreativeResult(processing, creative);
          }

          const startedImageCreatives = processing.getStartedCreatives()
            .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

          processing.groups.findIndex((g) => g === creative.group);

          webviewAnalyticsEvent("generation_finish", [
            clientStorage.getSelectedPhotosAmount(),
            startedImageCreatives.length,
            processing.groups.findIndex((g) => g === creative.group) + 1,
            resolveCreativeAnalyticsName(creative),
            creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
            creative.getExtra("refresh_amount", 0),
          ], {
            wt_barbify3: {
              seed: creative.getExtra("seed", 0),
              gender: creative.getExtra("gender")
            },
          });
        })
        .catch((creative) => {
          this.tick();
          logEvent(userEvents.CREATIVE_FAILED, {
            group: creative.group,
            template_id: creativeName(creative),
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
            error: creative.error,
            files: processing.files,
            seed: creative.getExtra("seed", 0)
          });
          logCreativeResult(
            `${creative.group}_${creative.templateId}`,
            processing.files.map((f) => ({url: f.url})),
            [{error: creative.error}],
            false,
            {
              gender: creative.getExtra(extraKeys.gender),
              combo: creative.getExtra(Creative.EXTRA_COMBO_STEPS),
            }
          );

          const startedImageCreatives = processing.getStartedCreatives()
            .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image);

          processing.groups.findIndex((g) => g === creative.group);

          webviewAnalyticsEvent("generation_error", [
            clientStorage.getSelectedPhotosAmount(),
            startedImageCreatives.length,
            processing.groups.findIndex((g) => g === creative.group) + 1,
            resolveCreativeAnalyticsName(creative),
            creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
            creative.getExtra("refresh_amount", 0),
          ], {
            wt_barbify3: {
              seed: creative.getExtra("seed", 0),
              gender: creative.getExtra("gender")
            },
          });
        });

      this._handlers.push({
        id: creative.id,
        handler: handlerPromise,
      });
    });

    this.tick();
  };

  /** @return {Processing} */
  restore = () => {
    const storedValue = window.localStorage.getItem(localStorageKey);
    if (storedValue != null) {
      const processing = new Processing();
      processing.fromObject(JSON.parse(storedValue));

      return processing;
    }

    return null;
  };

  /** @return {boolean} */
  hasStoredProcessing = () => {
    return window.localStorage.getItem(localStorageKey) != null;
  };

  stop = () => {
    this._processing = null;
  };

  clear = () => {
    window.localStorage.removeItem(localStorageKey);
    this.stop();
  };

  tickImmediately = () => {
    if (!this.processing) {
      return;
    }

    this.commit(this.processing.toJSON());

    this._listeners.forEach((listener) => {
      listener.call(null, this.processing);
    });
  };

  tick = throttle(200, this.tickImmediately);

  commit = (data, commitToApi = false) => {
    window.localStorage.setItem(localStorageKey, data);

    if (commitToApi) {
      this.commitToApi(data);
    }
  };

  commitProcessing = () => {
    this.processing && this.commit(this.processing.toJSON());
  };

  commitToApi = (data) => {
    clearTimeout(this.commitTimer);
    this.commitTimer = setTimeout(() => {
      api.processingCommit(data).then(() => {
        console.info("Processing committed");
      }).catch((err) => {
        console.error("Processing commit is failed", err);
      });
    }, 2000);
  };

  addOnProcessingChangeHandler = (listener) => {
    this._listeners.push(listener);
  };

  removeOnProcessingChangeHandler = (listener) => {
    const pos = this._listeners.indexOf(listener);
    if (pos >= 0) {
      this._listeners.splice(pos, 1);
    }
  };
}

export default new ProcessingManager();
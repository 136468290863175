import React from "react";
import styled from "styled-components";

export const AppStoreLinkStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(17, 17, 17);
  border-radius: 12px;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  max-width: 174px;
  margin-left: 12px;

  ${props => props.marginTop && `
    margin-left: 0px;
    margin-top: 12px;
  `}

  &:first-child {
    margin: 0;
  }

  svg {
    margin-right: 12px;
  }

  p {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.1px;
    margin: 0;
    text-align: left;
    color: rgb(17, 17, 17);

    &.store {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: -0.16px;
    }
  }
`;

export const AppStoreLink = (props) => {
  return <AppStoreLinkStyled href={props.link} target="_blank" {...props}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6332 15.0718C20.6025 11.9939 23.1431 10.5177 23.2567 10.4431C21.8289 8.35543 19.6044 8.06898 18.8127 8.03638C16.9211 7.84426 15.1199 9.15059 14.1603 9.15059C13.2026 9.15059 11.72 8.06453 10.1514 8.09219C8.0879 8.12281 6.18594 9.29234 5.12408 11.1395C2.9811 14.857 4.57586 20.3663 6.66402 23.3835C7.68489 24.8582 8.90232 26.5177 10.4996 26.4569C12.039 26.3962 12.6198 25.4612 14.4808 25.4612C16.3418 25.4612 16.8643 26.4569 18.4926 26.4258C20.1482 26.3957 21.1977 24.9229 22.2101 23.4422C23.3821 21.7289 23.8647 20.07 23.8933 19.986C23.8573 19.9692 20.6662 18.7468 20.6332 15.0718Z" fill="#111111" />
      <path d="M17.5735 6.03965C18.4206 5.01137 18.9945 3.58255 18.8384 2.16016C17.616 2.20955 16.1363 2.9726 15.2587 3.99989C14.4724 4.91161 13.7849 6.36314 13.9691 7.75986C15.3328 7.86555 16.7236 7.06595 17.5735 6.03965Z" fill="#111111" />
    </svg>
    <div>
      <p>Download on the</p>
      <p className="store">App Store</p>
    </div>
  </AppStoreLinkStyled>;
}

export const PlayStoreLink = props => {
  return <AppStoreLinkStyled href={props.link} target="_blank" {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6504 7.64617L5.36547 1.60454C4.77903 1.29059 4.14314 1.27455 3.59277 1.47521L13.2071 11.0895L16.6504 7.64617Z" fill="#111111" />
      <path d="M20.8588 9.8992L17.6081 8.15894L13.9423 11.8247L17.6081 15.4905L20.8587 13.7502C22.3964 12.927 22.3964 10.7224 20.8588 9.8992Z" fill="#111111" />
      <path d="M2.71259 2.06543C2.36779 2.44439 2.15045 2.95032 2.15045 3.52998V20.1193C2.15045 20.699 2.36779 21.205 2.71255 21.5839L12.4718 11.8247L2.71259 2.06543Z" fill="#111111" />
      <path d="M3.59277 22.1743C4.1431 22.375 4.77903 22.359 5.36547 22.045L16.6504 16.0034L13.2071 12.5601L3.59277 22.1743Z" fill="#111111" />
    </svg>
    <div>
      <p>Download on the</p>
      <p className="store">Google Play</p>
    </div>
  </AppStoreLinkStyled>;
}
import React from "react";
import i18n from "../../i18n";
import routes from "../../routes";
import {debounce} from "../../utils/etc";
import AppContext from "../../contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import FileChooseButton from "../../components/FileChooseButton";
import processingManager from "../../photolab/ProcessingManager";
import MainTitle from "../../components/MainTitle/MainTitle";
import {
  AppStoreLinksContainerStyled,
  IndexPageFooterLinkStyled,
  IndexPageFooterLinksContainerStyled,
  IndexPageProccessingContainerStyled,
  IndexPageProccessingFinalStyled,
  IndexPageProccessingOriginalStyled,
  IndexPageStyled, IndexPageBarbifyProccessingOriginalStyled, IndexPageBarbifyProccessingFinalStyled
} from "./IndexPage.style";
import AppHeader from "../../components/AppHeader/AppHeader";
import Button from "../../components/Button/Button";
import Processing from "../../photolab/Processing";
import {webviewAnalyticsEvent} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import {signalEvent, signals} from "../../utils/signals";
import {DOLLME_APPSTORE_LINK, DOLLME_PLAY_MARKET_LINK} from "../../utils/constants";
import AppStoreLinks from "../shared/AppStoreLinks";
import {getBarbieAiBodyPrompts} from "../../photolab/config";

export default class IndexPage extends React.Component {

  state = {
    isWebviewLoaded: false,
    isDesktopLoaded: false,
  };

  componentDidMount() {
    console.log("'" + getBarbieAiBodyPrompts().map((c) => c.templateId).join("','") + "'");

    if (window.clientConfig.isWebview) {
      const restoredProcessing = processingManager.restore();
      if (restoredProcessing != null) {
        const startedAt = restoredProcessing.getExtra(Processing.EXTRA_STARTED_AT);
        if (startedAt + (3 * 3_600_000) >= Date.now()) {
          this.props.history.replace(routes.PROCESSING);
          return;
        }
      }
    }

    window.webviewEventsListeners.tabSelected.subscribe(this.handleWebviewTabSelected, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_INDEX);
      this.setState({isDesktopLoaded: true})
      window.addEventListener("resize", this.handleResizeScreen);
    } else if (window.clientConfig.isWebviewAndroid) {
      this.setState({isWebviewLoaded: true})
    }
  }

  componentWillUnmount() {
    window.webviewEventsListeners.tabSelected.unsubscribe(this.handleWebviewTabSelected);
    window.removeEventListener("resize", this.handleResizeScreen);
  }

  handleResizeScreen = () => {
    this.setState({isDesktopLoaded: false});
    setTimeout(() => {
      this.setState({isDesktopLoaded: true});
    }, 0);
  }

  handleWebviewTabSelected = (millis) => {
    if (millis > 0) {
      debounce("IndexPage.handleWebviewTabSelected", 300, () => {
        hitEvent(hits.INDEX_VISIT);
        logEvent(userEvents.PAGE_INDEX);
      });

      if (window.clientConfig.isWebviewIOS) {
        this.setState({isWebviewLoaded: true});
      }
    }
  };

  handleFilesSelected = (files) => {
    clientStorage.incrementSelectedPhotosAmount();

    hitEvent(hits.INDEX_PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {place: "index"});
    webviewAnalyticsEvent("photo_selected", [
      clientStorage.getSelectedPhotosAmount(),
    ]);

    processingManager.clear();

    this.props.history.replace({
      pathname: routes.PROCESSING,
      state: {files},
    });
  };

  handleFileChooseButtonClick = () => {
    signalEvent(signals.photoSelectClick);
    webviewAnalyticsEvent("button_tapped", ["", "choose_photo"]);
  };

  handleGetAppButtonClick = () => {
    if (window.clientConfig.isAnyAndroid) {
      setTimeout(() => window.location.href = DOLLME_PLAY_MARKET_LINK, 300);
    } else {
      setTimeout(() => window.location.href = DOLLME_APPSTORE_LINK, 300);
    }
  };

  renderButton = () => {
    let getAppButton;

    if (window.clientConfig.isWeb && !window.appConfig.processings.allowOnWeb) {
      if (!window.clientConfig.isAnyMobile) {
        getAppButton = <Button primary onClick={this.handleGetAppButtonClick}>
          {i18n.t("get_the_app")}
        </Button>;
      } else {
        return null;
      }
    }

    return <FileChooseButton
      primary
      className="btn-upload-foto"
      onClick={this.handleFileChooseButtonClick}
      onFilesSelected={this.handleFilesSelected}
    >
      {i18n.t("upload_choose_photo_button")}
    </FileChooseButton>;
  };

  renderStoreButtons = () => {
    if (window.clientConfig.isWebMobile) {
      if (window.clientConfig.isAnyIOS) {
        return <AppStoreLinks appStore />;
      } else {
        return <AppStoreLinks playStore />;
      }
    } else {
      return <AppStoreLinks appStore playStore />;
    }
  }

  render() {
    const classNames = ["main-page"];
    const logoText = window.appConfig.isBarbifyMe
      ? i18n.t("index_logo_barbify_me")
      : i18n.t("index_logo");
    const titleText = window.appConfig.isBarbifyMe
      ? i18n.t("index_title_barbify_me")
      : i18n.t("index_title");
    const subtitleText = window.appConfig.isBarbifyMe
      ? i18n.t("index_subtitle_barbify_me")
      : i18n.t("index_subtitle");

    return <IndexPageStyled className={classNames.join(" ")}>
      <div className="container">
        <AppHeader>{logoText}</AppHeader>
        <IndexPageProccessingContainerStyled className={`${this.state.isWebviewLoaded ? 'load_webview' : ''} ${this.state.isDesktopLoaded ? 'load_desktop' : ''}`}>
          {window.appConfig.isBarbifyMe ? <>
            <IndexPageBarbifyProccessingOriginalStyled />
            <IndexPageBarbifyProccessingFinalStyled />
          </> : <>
            <IndexPageProccessingOriginalStyled />
            <IndexPageProccessingFinalStyled />
          </>}
        </IndexPageProccessingContainerStyled>
        <div className="content-container">
          <MainTitle dangerouslySetInnerHTML={{__html: titleText}} />
          <p className={`italic ${window.clientConfig.isWebview ? "webview" : ""}`} dangerouslySetInnerHTML={{__html: subtitleText}} />

          <FileChooseButton
            hidden={window.clientConfig.isWeb && !window.appConfig.processings.allowOnWeb}
            primary
            className={`btn-upload-foto`}
            onClick={this.handleFileChooseButtonClick}
            onFilesSelected={this.handleFilesSelected}
          >
            {i18n.t("upload_choose_photo_button")}
          </FileChooseButton>

          <AppStoreLinksContainerStyled justify="center" hidden={window.clientConfig.isWebview}>
            {this.renderStoreButtons()}
          </AppStoreLinksContainerStyled>
          <IndexPageFooterLinksContainerStyled justify="center" hidden={window.clientConfig.isWebview}>
            <IndexPageFooterLinkStyled href="https://pho.to/privacy" target="_blank">{i18n.t("index_footer_privacy")}</IndexPageFooterLinkStyled>
            <IndexPageFooterLinkStyled href="mailto:contact@toonme.com">{i18n.t("index_footer_contact")}</IndexPageFooterLinkStyled>
          </IndexPageFooterLinksContainerStyled>
        </div>
      </div>
    </IndexPageStyled>;
  }
}

IndexPage.contextType = AppContext;
export const DOLLME_PLAY_MARKET_LINK = "https://play.google.com/store/apps/details?id=com.informe.barbify";
export const DOLLME_APPSTORE_LINK = "https://apps.apple.com/app/dollme-avatar-doll-maker/id6447503630";
export const TOONME_PLAY_MARKET_LINK = "https://play.google.com/store/apps/details?id=com.vicman.toonmeapp&referrer=apptoonmecom";
export const TOONME_APPSTORE_LINK = "https://apps.apple.com/app/id1508120751";

export const iosRequestParams = {
  app_id: 0,
  app_version: "",
  config_id: 0,
  country: "",
  device: "",
  e_category: "",
  event_id: 0,
  event_num: 0,
  first_install_date: 0,
  idfa: "",
  is_pro: 0,
  lang: "",
  last_tab: "",
  localts: 0,
  localtz: "",
  network: "",
  os_version: "",
  photo_chooser_id: 0,
  postprocessing_ad_idx: 0,
  processing_ad_idx: 0,
  session_idx: 0,
  subs_id: "",
  subs_state: "",
  token: "",
  uuid: "",
  version_code: 0,
  vicman_unified_id: "",
  geoip_country: ""
};

export const androidRequestParams = {
  app_id: 0,
  app_version: "",
  version_code: 0,
  device: "",
  os: "",
  os_version: "",
  os_version_code: 0,
  screen_density: "",
  screen_w: 0,
  screen_h: 0,
  is_tablet: 0,
  plid: "",
  country: "",
  lang: "",
  network: "",
  android_id: "",
  config_id: "",
  session_idx: 0,
  localtz: "",
  aid: "",
  param0: "",
  param1: "",
  param5: "",
  param6: "",
  last_tab: "",
  is_pro: 0,
  subs_id: "",
  subs_state: "",
  processing_ad_idx: 0,
  postprocessing_ad_idx: 0,
  idfa: "",
  geoip_country: ""
};
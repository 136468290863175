import React from "react";
import AppContext from "../../contexts/AppContext";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import Processing from "../../photolab/Processing";
import {
  photolabGenderTask,
  PhotolabResponseError,
  PhotolabResponseParseError,
  photolabSimpleTask
} from "../../photolab/api";
import uploadHandler from "../../utils/upload.handler";
import {
  createAiBodyCreativeByPromptConfig,
  createCreativesByPromptConfig,
  getBarbieAiBodyPrompts,
  getPrompts
} from "../../photolab/config";
import ErrorView from "../../components/ErrorView";
import routes from "../../routes";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../../utils/log";
import LoadingView from "./LoadingView/LoadingView";
import clientStorage from "../../utils/client-storage";
import {normalizeError} from "../../photolab/handlers/helpers";
import {extraKeys, typeKeys} from "../../photolab/etc";
import {webviewAnalyticsEvent} from "../../utils/webview";
import {signalEvent, signals} from "../../utils/signals";
import * as processingHelper from "../../helpers/processing.helper";
import {resolveCreativeAnalyticsName} from "../../utils/creative";

export default class ProcessingPage extends React.Component {

  state = {
    gender: null,
    error: null,
    imagesUrls: [],
    imageIsBlurred: false,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    const locationState = this.props.location.state || {};
    const files = clientStorage.hasLatestSelectedImages()
      ? clientStorage.getLatestSelectedImages()
      : locationState.files;

    const processing = processingManager.restore();

    if (processing) {
      processingManager.start(processing);
    } else if (files) {
      this.handleUploadFiles(files);
    } else {
      processingManager.clear();
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      webviewAnalyticsEvent("app_enter_background", [
        clientStorage.getSelectedPhotosAmount(),
        1,
        "",
        "",
        "",
        "generation",
      ]);

      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  handleProcessingChanged = () => {
    if (window.clientConfig.isDebug) {
      console.log("Processing changed", processingManager.processing);
    }

    const processing = processingManager.processing;
    const startedCreatives = processing.getStartedCreatives();
    const processedCreatives = startedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = startedCreatives.filter((c) => c.isFailed);
    const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);
    const croppedImagesUrls = processing.getExtra(Processing.EXTRA_CROPPED_IMAGES_URLS, this.state.imagesUrls);

    this.setState({
      imagesUrls: croppedImagesUrls,
      imageIsBlurred: croppedImagesUrls.length === 0,
      gender: processing.getExtra(extraKeys.gender, this.state.gender),
    });

    if (processedCreatives.isNotEmpty()) {
      this.stopProcessingTimer();

      processingHelper.runOnceByExtra(processing, "processing_processed", () => {
        hitEvent(hits.PROCESSING_PROCESSED);
        signalEvent(signals.processingProcessed);
        logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
        logProcessingsTimings(elapsedMs);
      });

      this.props.history.replace(routes.RESULT);
    } else if (failedCreatives.length === startedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        signalEvent(signals.processingFailedPhotolab);
      } else {
        hitEvent(hits.PROCESSING_FAILED);
        signalEvent(signals.processingFailed);
      }

      logEvent(userEvents.PROCESSING_FAILED, {elapsed_time_ms: elapsedMs});

      processingManager.clear();

      this.setState({
        error: failedCreative.error,
      });
    }
  };

  startProcessing = async (files) => {
    try {
      clientStorage.incrementSelectedPhotosAmount();

      const prepareTaskResult = await photolabSimpleTask(8205, files.first(), 500, 500);
      const cropV15TaskResult = await photolabSimpleTask(6637, prepareTaskResult.resultUrl, 500, 500);
      const cropSdTaskResult = await photolabSimpleTask(8659, cropV15TaskResult.resultUrl, 500, 500);
      const file = {
        url: cropSdTaskResult.resultUrl,
      };

      const genderTaskResult = await photolabGenderTask(file.url).then((taskResult) => taskResult.gender.value);
      const croppedImagesUrls = [file.url];
      const gender = genderTaskResult || "female";

      const processing = new Processing();
      processing.setId(Date.now());
      processing.setLanguage(window.clientConfig.lang);
      processing.setFile(file, [0]);
      processing.setExtra(extraKeys.version, window.appConfig.processings.latestVersion);
      processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
      processing.setExtra(Processing.EXTRA_CROPPED_IMAGES_URLS, croppedImagesUrls);
      processing.setExtra(Processing.EXTRA_RANDOM_GENDER, ["male", "female"].random());
      processing.setExtra(extraKeys.gender, gender);
      processing.setExtra(extraKeys.isPro, window.clientConfig.isPro);

      webviewAnalyticsEvent("photo_uploaded", [
        clientStorage.getSelectedPhotosAmount(),
        gender,
      ]);

      const groups = [];
      const prompts = getBarbieAiBodyPrompts()
        .filter((c) => c.genders.indexOf(gender) > -1)
        .shuffle();

      if (!window.clientConfig.isWeb) {
        prompts.push(...getPrompts(gender).filter((c) => c.genders.indexOf(gender) > -1));
      }

      prompts.forEach((prompt, index) => {
        groups.push(prompt.id);

        let imageCreative;
        let videoCreative;

        if (prompt.id === "m_barbie_ai_body" || prompt.id === "barbie_ai_body") {
          imageCreative = createAiBodyCreativeByPromptConfig(processing, prompt, {gender});
        } else {
          [imageCreative, videoCreative] = createCreativesByPromptConfig(processing, prompt, {gender});
        }

        if (window.appConfig.isTesterMode) {
          imageCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);
        }

        if (index === 0) {
          imageCreative.setAsSelected(true);
        }

        processing.addCreative(imageCreative);

        if (videoCreative) {
          processing.addCreative(videoCreative);
        }
      });

      processing.setGroups(groups.unique());
      processing.setExtra(Processing.EXTRA_ITEMS, prompts);

      const creative = processing.creatives.find((c) => {
        return c.group === groups[0] && c.isSelected;
      });

      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);

      processingManager.start(processing);
      signalEvent(signals.processingStarted);

      this.setState({
        imagesUrls: croppedImagesUrls,
        imageIsBlurred: false,
        gender: processing.getExtra(extraKeys.gender),
      });

      this.startProcessingTimer();

      webviewAnalyticsEvent("generation_start", [
        clientStorage.getSelectedPhotosAmount(),
        1,
        1,
        resolveCreativeAnalyticsName(creative),
        creative.getExtra(extraKeys.type) === typeKeys.image ? "photo" : "video",
        creative.getExtra("refresh_amount", 0),
      ], {
        wt_barbify3: {
          seed: creative.getExtra("seed", 0),
          gender: creative.getExtra("gender")
        },
      });
    } catch (err) {
      console.error(err);

      processingManager.clear();

      const isPhotolabError = err instanceof PhotolabResponseError
        || err instanceof PhotolabResponseParseError;

      if (isPhotolabError) {
        signalEvent(signals.processingFailedPhotolab);
      } else {
        signalEvent(signals.processingFailed);
        logEvent(userEvents.PROCESSING_ERROR, {
          message: err.message,
          code: err.code,
        });

        webviewAnalyticsEvent("photo_upload_error", [
          clientStorage.getSelectedPhotosAmount(),
          "",
          err.code,
          err.message,
        ]);
      }

      this.setState({
        gender: null,
        imagesUrls: [],
        imageIsBlurred: false,
        error: normalizeError(err),
      });
    }
  }

  handleProcessingTimeout = () => {
    processingManager.clear();

    this.setState({
      gender: null,
      imagesUrls: [],
      imageIsBlurred: false,
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    });
  };

  handleFilesSelected = (files) => {
    this.setState({
      gender: null,
      imagesUrls: [],
      imageIsBlurred: false,
      error: null,
    }, () => {
      processingManager.clear();
      this.handleUploadFiles(files);
    });
  }

  handleUploadFiles = (files) => {
    if (!(files instanceof FileList)) {
      this.handleFilesUploaded(files);
      return;
    }

    Promise.all([...files].map((image) => uploadHandler(image)))
      .then(this.handleFilesUploaded)
      .catch((error) => {
        this.setState({error});
      });
  }

  handleFilesUploaded = (files) => {
    this.setState({
      imagesUrls: [files.first().url],
      imageIsBlurred: true,
    });
    this.startProcessing(files);
  }

  handleCancel = () => {
    processingManager.clear();
    this.props.history.replace(routes.INDEX);
  }

  // handleGenderChanged = (nextGender) => {
  //   this.setState({gender: nextGender});
  //
  //   const processing = processingManager.processing;
  //   if (!processing) {
  //     return;
  //   }
  //
  //   const prompt = processing.getItemByPosition(0, nextGender);
  //   const currentCreative = processing.getSelectedCreativeByPosition(0);
  //
  //   let nextCreative = processing.getCreativeByParams(
  //     0,
  //     nextGender,
  //     currentCreative.getExtra(extraKeys.contextType),
  //     currentCreative.getExtra(extraKeys.body),
  //     currentCreative.getExtra(extraKeys.stylization)
  //   );
  //
  //   if (!nextCreative) {
  //     nextCreative = createCreativeByPromptConfig(
  //       processing,
  //       prompt,
  //       nextGender,
  //       currentCreative.getExtra(extraKeys.stylization),
  //       currentCreative.getExtra(extraKeys.body),
  //       currentCreative.getExtra(extraKeys.contextType)
  //     );
  //
  //     nextCreative.setExtra(Creative.EXTRA_POSITION, 0);
  //     processing.addCreative(nextCreative);
  //   }
  //
  //   nextCreative.setAsSelected(true);
  //   currentCreative.setAsSelected(false);
  //
  //   processing.setExtra(extraKeys.gender, nextGender);
  //   processingManager.update();
  // }

  handleRestartButtonClick = () => {
    processingManager.clear();

    const redirectUrl = new URL(window.location.href);
    redirectUrl.pathname = "/";
    redirectUrl.search = "";

    if (window.clientConfig.isWebview) {
      Object.keys(window.clientConfig.webviewParams).forEach((key) => {
        redirectUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
      });
    }

    redirectUrl.searchParams.append("r", Math.round(100000 * Math.random()));

    window.location.href = redirectUrl.toString();
  };

  handleRetryButtonClick = () => {
    const locationState = this.props.location.state || {};
    const files = clientStorage.hasLatestSelectedImages()
      ? clientStorage.getLatestSelectedImages()
      : locationState.files;

    this.handleFilesSelected(files);
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFilesSelected={this.handleFilesSelected}
        onRestartClick={this.handleRestartButtonClick}
        onRetryClick={this.handleRetryButtonClick}
      />;
    }

    return <LoadingView
      imagesUrls={this.state.imagesUrls}
      imageIsBlurred={this.state.imageIsBlurred}
      gender={/*this.state.gender*/ null}
      // onGenderChanged={this.handleGenderChanged}
      onCancel={this.handleCancel}
    />;
  }
}

ProcessingPage.contextType = AppContext;

import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import {defaultHandlerCatch, defaultHandlerResolver, promisifyImage} from "./helpers";
import {photolabTask} from "../api";
import {createMd5Token} from "../../utils/text";
import {pwAssetUrl} from "../../utils/etc";
import {hitEvent, hits} from "../../utils/log";
import axios from "axios";
import Processing from "../Processing";
import {extraKeys, taskKeys} from "../etc";
import Creative from "../Creative";
import * as imagesStoreHelper from "../../helpers/images-store.helper";

const addedOptions = {
  timeout: 1000,
  interval: 1000,
};

const axiosClient = axios.create();
const tasksCache = {};
const dummyImage = "https://assets.photo-cdn.net/templates-previews/wt_d19_barbie_female_6.jpg";

function photolabTaskHelper(creative, taskName, taskConfig, options) {
  const taskCacheKey = JSON.stringify(taskConfig);
  const _options = Object.assign({
    timeout: 1000,
    interval: 1000,
  }, options);


  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(taskConfig, {
      timeout: _options.timeout,
      interval: _options.interval,
    })
  }

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask(taskName, taskResult);
    return taskResult;
  })
}

async function cropV15Task(processing) {
  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod({template_name: 6637}))
    .addImage(new PhotolabTaskImageUrl(processing.files.first().url, "", 0, 0))
    .buildToJs();

  return photolabTask(taskConfig, {
    timeout: addedOptions.timeout,
    interval: addedOptions.interval,
  });
}

async function checkCache(creative, cacheImageName) {
  const cacheImageUrl = pwAssetUrl("task_cache/" + cacheImageName);

  try {
    await axiosClient.head(cacheImageUrl);

    hitEvent(hits.D22_CACHE_HIT, 1, true);

    return {resultUrl: cacheImageUrl};
  } catch (err) {
    hitEvent(hits.D22_CACHE_MISS, 1, true);
  }

  return null;
}

async function delay(delayMs, startAtMs) {
  return new Promise((resolve) => {
    const endAt = (startAtMs || Date.now()) + delayMs;
    const left = endAt - Date.now();

    if (left > 0) {
      setTimeout(resolve, left);
    } else {
      resolve();
    }
  });
}

async function questionsTask(processing, fileUrl) {
  const questions = [
    "What gender is this person?",
    "What hair color this person has?",
    "What color of human skin this person has?",
  ];

  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod({
      template_name: "nn_blip",
      questions: `[|${questions.join("|, |")}|]`
    }))
    .addImage(new PhotolabTaskImageUrl(fileUrl, "", 0, 0))
    .buildToJs();

  return photolabTask(taskConfig, {
    timeout: addedOptions.timeout,
    interval: addedOptions.interval,
  });
}

async function promptTask(processing, creative) {
  const {answers} = processing.getTask(taskKeys.questions);
  const selectedHairColor = processing.getExtra(Processing.EXTRA_SELECTED_HAIR_COLOR);
  const hairColor = (selectedHairColor && selectedHairColor !== "other")
    ? selectedHairColor
    : answers[1];

  const params = {
    "@transformationId": "npp_base",
    "@configId": creative.templateId,
    param_sds: creative.getExtra("seed"),
    gender: creative.getExtra("gender"),
    "@replacements": [
      {
        parameters: ["prompt"],
        search: "{{hair_color}}",
        mode: "ig",
        replacement: hairColor,
      },
      {
        parameters: ["prompt"],
        search: "{{skin_color}}",
        mode: "ig",
        replacement: answers[2],
      },
    ],
  };

  if (creative.getExtra(extraKeys.withHairLength, false)) {
    params["@replacements"].push({
      parameters: ["prompt"],
      search: "{{hair_length}}",
      mode: "ig",
      replacement: processing.getExtra(Processing.EXTRA_SELECTED_HAIR_STYLE, ""),
    });
  }

  const paramsHash = createMd5Token(JSON.stringify({...params, salt: "20230810"}));
  const cacheImageName = "d22_" + paramsHash + ".jpeg";

  const cacheTask = await checkCache(creative, cacheImageName);
  if (cacheTask != null) {
    creative.setTaskConfig("prompt", cacheTask);

    await delay(10_000, creative.getExtra(Creative.EXTRA_STARTED_AT));

    return cacheTask;
  }

  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(params))
    .addImage(new PhotolabTaskImageUrl(dummyImage))
    .buildToJs();

  const taskResult = await photolabTaskHelper(creative, "prompt", taskConfig);

  imagesStoreHelper.store({
    disk: "task_cache",
    file: taskResult.resultUrl,
    fileName: cacheImageName,
  }).then().catch();

  return taskResult;
}

async function promptWithRefinerTask(processing, creative) {
  const {answers} = processing.getTask(taskKeys.questions);
  const selectedHairColor = processing.getExtra(Processing.EXTRA_SELECTED_HAIR_COLOR);
  const hairColor = (selectedHairColor && selectedHairColor !== "other")
    ? selectedHairColor
    : answers[1];

  const firstTaskParams = {
    "@transformationId": "npp_base",
    "@configId": creative.templateId,
    param_sds: creative.getExtra("seed"),
    gender: creative.getExtra("gender"),
    "@replacements": [
      {
        parameters: ["prompt"],
        search: "{{hair_color}}",
        mode: "ig",
        replacement: hairColor,
      },
      {
        parameters: ["prompt"],
        search: "{{skin_color}}",
        mode: "ig",
        replacement: answers[2],
      },
    ],
  };

  const secondTaskParams = {
    "@transformationId": "npp_base",
    "@configId": creative.getExtra("refiner_config_id"),
    "@replacements": [
      {
        parameters: ["prompt"],
        search: "{{hair_color}}",
        mode: "ig",
        replacement: hairColor,
      },
      {
        parameters: ["prompt"],
        search: "{{skin_color}}",
        mode: "ig",
        replacement: answers[2],
      },
    ],
  };

  if (creative.getExtra(extraKeys.withHairLength, false)) {
    firstTaskParams["@replacements"].push({
      parameters: ["prompt"],
      search: "{{hair_length}}",
      mode: "ig",
      replacement: processing.getExtra(Processing.EXTRA_SELECTED_HAIR_STYLE, ""),
    });
    secondTaskParams["@replacements"].push({
      parameters: ["prompt"],
      search: "{{hair_length}}",
      mode: "ig",
      replacement: processing.getExtra(Processing.EXTRA_SELECTED_HAIR_STYLE, ""),
    });
  }

  const firstTaskParamsHash = createMd5Token(JSON.stringify({...firstTaskParams, salt: "20230810"}));
  const cacheImageName = "d22_" + firstTaskParamsHash + "_wr.jpeg";

  const cacheTask = await checkCache(creative, cacheImageName);
  if (cacheTask != null) {
    creative.setTaskConfig("prompt", cacheTask);
    creative.setTaskConfig("prompt_refiner", cacheTask);

    await delay(10_000, creative.getExtra(Creative.EXTRA_STARTED_AT));

    return cacheTask;
  }

  const firstTaskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(firstTaskParams))
    .addImage(new PhotolabTaskImageUrl(dummyImage))
    .buildToJs();

  const firstTaskResult = await photolabTaskHelper(creative, "prompt", firstTaskConfig);

  const secondTaskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(secondTaskParams))
    .addImage(new PhotolabTaskImageUrl(firstTaskResult.resultUrl))
    .buildToJs();

  const secondTaskResult = await photolabTaskHelper(creative, "prompt_refiner", secondTaskConfig);

  imagesStoreHelper.store({
    disk: "task_cache",
    file: secondTaskResult.resultUrl,
    fileName: cacheImageName,
  }).then().catch();

  return secondTaskResult;
}

function finalTask(processing, creative, fileUrl, promptTaskResult) {
  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod({template_name: 8896}))
    .addImage(new PhotolabTaskImageUrl(fileUrl))
    .addImage(new PhotolabTaskImageUrl(promptTaskResult.resultUrl))
    .buildToJs();

  return photolabTaskHelper(creative, "final", taskConfig);
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  return new Promise(async (resolve, reject) => {
    try {
      let cropV15TaskResult = processing.getTask(taskKeys.cropV15);
      if (!cropV15TaskResult) {
        cropV15TaskResult = await cropV15Task(processing);
        processing.setTask(taskKeys.cropV15, cropV15TaskResult);
      }

      let questionTaskResult = processing.getTask(taskKeys.questions);
      if (!questionTaskResult) {
        questionTaskResult = await questionsTask(processing, cropV15TaskResult.resultUrl);
        processing.setTask(taskKeys.questions, questionTaskResult);
      }

      const promptTaskResult = creative.hasExtra("refiner_config_id")
        ? await promptWithRefinerTask(processing, creative)
        : await promptTask(processing, creative);

      const finalTaskResult = await finalTask(processing, creative, cropV15TaskResult.resultUrl, promptTaskResult);
      let resultUrl = finalTaskResult.resultUrl;

      if (creative.hasExtra(extraKeys.sdxlWatermarkTemplate)) {
        const wmTaskConfig = new PhotolabTaskBuilder()
          .addImage(new PhotolabTaskImageUrl(resultUrl))
          .addMethod(new PhotolabTaskCollageMethod({
            template_name: creative.getExtra(extraKeys.sdxlWatermarkTemplate),
          }))
          .setLanguage(processing.language)
          .buildToJs();

        const wmTaskResult = await photolabTaskHelper(creative, "wm", wmTaskConfig);
        resultUrl = wmTaskResult.resultUrl;
      }

      await promisifyImage(resultUrl);

      creative.setFile("raw", resultUrl);
      creative.markAsProcessed(resultUrl);

      defaultHandlerResolver(creative, resolve)();
    } catch (err) {
      defaultHandlerCatch(creative, reject)(err);
    }
  });
}
